.manufacturers-list-in-rows {
  padding: 2rem 1rem 2rem 1rem;

  .ilc-manufacturer-row-container {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    .ilc-manufacturer-row {
      width: 80%;

      .ilc-manufacturer-row-content {
        display: flex;
        cursor: pointer;
        align-items: center;

        .manufacturer-logo-in-row {
          flex-basis: 30%;
        }

        .manufacturer-details-column-in-row {
          display: flex;
          flex-direction: column;
          margin-left: 1rem;
          flex-basis: 70%;

          .manufacturer-description-in-row {
            font-size: 1.1rem;
            line-height: 1.3;
          }

          .manufacturer-name-in-row {
            margin-bottom: 0.8rem;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
