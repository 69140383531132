.ilc-right-news-and-events li {
  list-style: none;
}

.ilc-news-and-events {
  display: flex;
  flex-flow: row wrap;
  margin-left: 0;
  min-height: 400px;
  margin-bottom: 50px;
  width: 100%;
  margin-right: 0;
}

.ilc-is-mobile .ilc-news-and-events {
  margin: auto;
}

.ilc-news-and-events .ilc-feed {
  flex-basis: 75%;
  margin-right: 15px;
}
.ilc-news-and-events > h3 {
  flex-basis: 75%;
  margin-left: 16px;
  border-bottom: solid 1px #dedede;
  padding: 5px;
}
.ilc-news-and-events .ilc-news-right-bar {
  flex-basis: 20%;
  max-width: 20%;
}

@media screen and (max-width: 700px) {
  .ilc-news-and-events .ilc-feed,
  .ilc-news-and-events .ilc-news-right-bar,
  .ilc-news-and-events > h3 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.ilc-news-and-events .ilc-news-right-bar {
  padding-bottom: 15px;
  padding: 5px;
}
.ilc-news-and-events .ilc-news-right-bar ul {
  padding-left: 0;
}
.ilc-news-and-events .ilc-news-right-bar li {
  margin-bottom: 15px;
  border: solid 1px #dedede;
  padding: 15px;
  width: 90%;
}

.ilc-news-and-events .ilc-news-right-bar li img {
  max-width: 100%;
}
.ilc-news-and-events .ilc-news-right-bar li a:hover {
  color: inherit;
}
.ilc-news-and-events .ilc-news-right-bar .manufacturer_name_subtitle {
  font-style: italic;
  font-size: 14px;
  color: #bbb;
  margin-bottom: 4px;
}

.ilc-news-and-events .ilc-feed ul {
  width: 90%;
}

.ilc-news-and-events .ilc-feed ul {
  width: 90%;
}

.ilc-news-and-events .ilc-feed ul li {
  border-bottom: solid 1px #dedede;
  padding: 20px;
  margin-bottom: 15px;
}

.ilc-news-and-events .ilc-feed ul li img {
  max-width: 100%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.ilc-is-mobile .ilc-news-and-events .ilc-feed ul li img {
  margin-right: 0rem;
}

.ilc-news-and-events .ilc-feed ul li img:nth-child(2) {
  vertical-align: top;
  max-width: 17%;
  border: 1px solid #dedede;
  box-shadow: 5px 5px 10px #dedede;
  background: #fff;
}

.ilc-is-mobile .ilc-news-and-events .ilc-feed ul li img:nth-child(2) {
  vertical-align: baseline;
  margin-top: 1rem;
}

.ilc-news-and-events .ilc-feed ul li p {
  line-height: 2em;
  font-size: 16px;
  margin-top: 5px;
}

.ilc-news-and-events .ilc-feed ul li .read-more {
  margin-bottom: 15px;
}

.ilc-news-and-events .ilc-feed article .title {
  font-size: 1.33em;
  padding-bottom: 15px;
}

.ilc-news-and-events .ilc-feed ul li a:hover {
  color: inherit;
}

.ilc-news-and-events .ilc-feed article .subtitle {
  text-decoration: none;
  margin-bottom: 5px;
}
