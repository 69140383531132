[class$="-table"] {
  margin-bottom: 3rem;

  [class$="-table-header-container"] {
    background: #a9a9a9;

    [class$="-table-headers"] {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      color: white;
      align-items: center;
      [class$="-table-header-values"] {
        width: 12.5%;
        font-size: 0.8rem;
        margin: 0px;
        padding: 1rem;
      }
    }
  }

  [class$="-table-row-container"]:nth-child(even) {
    background: #f1f1f1;
  }
  [class$="-table-row-container"]:last-child {
    border-bottom: 1px solid #ebebeb;
  }

  [class$="-table-row"] {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #a9a9a9;
    border-left: 1px solid #a9a9a9;
    border-top: 1px solid #a9a9a9;

    [class$="-table-value-box"] {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 12.5%;
      font-size: 0.8rem;
      border-right: 1px solid #a9a9a9;
      vertical-align: middle;
      padding: 1rem;
    }
  }
}
