.ilc-calendar-masonry {
  display: flex;
  justify-content: center;
  align-items: center;

  .ilc-no-events-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ilc-events-grid {
    min-height: 400px;
    margin-bottom: 50px;
    width: 100%;

    .ilc-event-list {
      display: flex;
      flex-flow: row wrap;
      margin-left: 0;

      .ilc-event-tile {
        flex-basis: 100%;
        margin-right: 15px;
        border: solid 1px #dedede;
        font-size: 1.1em;
        margin-bottom: 20px;
        height: 22em;
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        @media screen and (min-width: 600px) {
          flex-basis: 47%;
        }

        @media screen and (min-width: 768px) {
          flex-basis: 31%;
        }

        @media screen and (min-width: 992px) {
          flex-basis: 23%;
        }

        p {
          padding: 0.7em;
          font-style: italic;
          font-size: 12px;
          margin-bottom: 0px;
        }

        .ilc-event-name {
          font-size: large;
          padding: 0;
          margin-left: 0.7em;
          margin-top: 0.5em;
          margin-bottom: 0.5em;
          height: 3em;
          overflow: hidden;
        }

        .ilc-event-img-container {
          width: 100%;
          height: 10em;
          display: flex;
          justify-content: center;
          align-items: center;

          .ilc-event-img {
            width: 100%;
            height: 100%;
          }

          .ilc-company-logo {
            width: 70%;
          }
        }

        .ilc-event-date,
        .ilc-event-time {
          font-weight: bold;
          font-style: normal;
          padding: 0;
          margin: 0;
          margin-left: 1em;
        }

        .ilc-company-name {
          padding: 0;
          margin: 1em;
        }

        .ilc-description {
          p {
            margin: 0;
            padding: 0;
            font-style: normal;
          }
          line-height: 1.4em;
          height: 4.2em;
          overflow: hidden;
          padding: 0;
          margin: 1em;
          margin-top: 0em;
          margin-bottom: 0.5em;
        }

        .ilc-event-rsvp {
          display: block;
          margin: 0.7em;
          margin-top: 0.3em;
          height: 1em;
        }
      }
    }
  }
}
