.ilc-quick-search-display-v2 {
  .ilc-mobile-filter-accordion-container {
    transform: translateX(0%);
    transition-duration: 600ms;
    margin-right: 1rem;
    flex-basis: 20%;
    .ilc-mobile-accordion-toggle {
      display: none;
    }
  }

  .ilc-collapse-container {
    text-align: right;
    margin-bottom: 7px;
    a {
      font-size: 0.9em;
      margin-left: 5px;
      cursor: pointer;
      color: #6095cc;
    }

    small {
      font-style: italic;
    }
  }

  .ilc-product-similar-collection-all-link {
    margin-left: 0;
  }

  .ilc-quick-search-v2-header {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;
    .ilc-header-text {
      display: none;
    }
    .ilc-search-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    .ilc-dropdown-tag-container {
      height: 25px;
    }

    @media screen and (max-width: 500px) {
      .ilc-search-actions {
        flex-direction: column;
      }
    }

    .ilc-input-container,
    .ilc-filter-toggle-container {
      position: relative;
    }

    .ilc-input-container,
    .ilc-searchbar-v2 {
      border-radius: 0 50px 50px 0;
    }
    @media screen and (max-width: 500px) {
      .ilc-input-container,
      .ilc-searchbar-v2 {
        border-radius: 50px;
      }
    }

    .ilc-filter-toggle-button,
    .ilc-filter-toggle-container {
      border-radius: 50px 0 0 50px;
    }
    @media screen and (max-width: 500px) {
      .ilc-filter-toggle-button,
      .ilc-filter-toggle-container {
        border-radius: 50px;
      }
    }

    .ilc-input-container {
      width: 28%;
    }
    @media screen and (max-width: 500px) {
      .ilc-input-container {
        width: 96%;
        margin: 0 auto;
      }
    }

    .ilc-filter-toggle-container {
      width: 18%;
    }
    @media screen and (max-width: 500px) {
      .ilc-filter-toggle-container {
        width: 96%;
        margin-bottom: 1.5em;
      }
    }
    @media screen and (min-width: 501px) and (max-width: 1200px) {
      .ilc-filter-toggle-container {
        width: 30%;
      }

      .ilc-input-container {
        width: 50%;
      }
    }

    .ilc-filter-toggle-button {
      background: #8c8c8c;
      border: 1px solid #8c8c8c;
      text-align: left;
      width: 100%;
      padding: 7px 0px 7px 1.5em;
      font-size: 1em;
      color: white;
      height: 32px;
      line-height: normal;
    }

    .ilc-searchbar-v2 {
      background: #fff;
      border: 1px solid #dedede;
      width: 100%;
      color: #8c8c8c;
      padding: 7px 0px 7px 2em;
      font-size: 1em;
      margin-bottom: 0px;
      height: 32px;
      line-height: normal;
      min-height: 0;
    }

    .ilc-tag-value {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0em 0em 0em 0.3em;
      font-size: 0.9em;
      color: white;
    }
    @media screen and (max-width: 500px) {
      .ilc-clear-products {
        margin-top: 2em;
        padding-left: 0;
        margin-left: 0;
        width: auto;
      }
    }

    .ilc-agency-total-products {
      display: inline-block;
      padding-left: 0;
      margin-bottom: 1em;
      width: 100%;
    }

    .ilc-searchbar-icon > svg {
      width: 14px;
      transform: rotate(100deg);
      font-weight: bold;
    }

    .ilc-searchbar-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .ilc-searchbar-icon > svg > path {
      fill: #8c8c8c;
    }

    .ilc-toggle-icon > svg {
      width: 14px;
      transform: rotate(180deg);
    }

    .ilc-toggle-icon {
      position: absolute;
      top: 9px;
      right: 13px;
    }

    .ilc-toggle-icon > svg > path {
      fill: #fff;
    }

    .ilc-filter-modal-overlay {
      height: 100%;
      width: 100%;
      position: fixed; /* Stay in place */
      z-index: 500; /* Sit on top */
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      overflow-x: hidden;
      transition: 0.5s;

      .ilc-filter-modal {
        position: absolute;
        background: #0f0f0f;
        width: 91%;
        margin-top: 10%;
        left: 50%;
        transform: translate(-50%, 0);
        max-width: 100vw;
        opacity: 0.9;
        @media screen and (max-width: 1024px) {
          width: 100%;
          left: 0;
          transform: unset;
          max-width: 100vw;
        }

        .ilc-modal-top {
          padding: 1em;
          width: 100%;
          .ilc-filter-modal-close {
            float: right;
            color: white;
            background: none;
            border: none;
            cursor: pointer;
            font-weight: 700;
          }
        }

        .ilc-filter-modal-content {
          padding: 1.3em;
          color: white;
          .ilc-dropdown-bins {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 1em;
            @media screen and (min-width: 601px) and (max-width: 1299px) {
              flex-direction: column;
            }
            @media screen and (max-width: 600px) {
              flex-direction: column;
              padding: 1em 0px 0px 0px;
            }
            .ilc-mobile-filter-accordion-container {
              @media screen and (min-width: 1300px) {
                flex-basis: 30%;
              }

              .ilc-mobile-accordion-toggle {
                @media screen and (min-width: 1300px) {
                  display: none;
                }
              }
            }
            &.ilc-with-programs-bin .ilc-mobile-filter-accordion-container {
              @media screen and (min-width: 1300px) {
                flex-basis: 23%;
              }
            }

            .ilc-dropdown-ilc-header__bin {
              padding-right: 1em;
              .ilc-dropdown-ilc-header__bin-header {
                background: #40404a;
                color: white;
                padding: 0.6em;
                font-size: 0.9em;
              }

              .ilc-dropdown-tags-list {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                list-style-type: none;
                padding-left: 0;
                margin-left: 0;
                margin-top: 1em;
                @media screen and (min-width: 601px) {
                  height: 500px;
                }
                @media screen and (min-width: 601px) and (max-width: 1299px) {
                  height: 325px;
                  align-content: start;
                }
                @media screen and (max-width: 600px) {
                  height: 550px;
                }
                .ilc-dropdown-tag-container {
                  @media screen and (min-width: 601px) and (max-width: 1299px) {
                    width: 33%;
                  }
                  height: 25px;
                }
              }
            }
          }
        }
      }
    }
  }

  .ilc-quick-search-v2-header.layout-v2 {
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
    background: #f7f7f7;
    display: flex;
    .ilc-header-text {
      @media screen and (max-width: 1200px) {
        width: 97%;
        padding-bottom: 0;
        padding-top: 0;
        margin-left: 1.3rem;
      }
      display: flex;
      flex-direction: column;
      width: 43%;
      margin-left: 2rem;
      margin-right: 2rem;
      padding-bottom: 2rem;
      padding-top: 2rem;
      h1 {
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 700;
        font-size: 32px;
        line-height: normal;
      }
      p {
        margin-top: 0.6rem;
        font-size: 17px;
        line-height: normal;
      }
    }

    .ilc-search-actions {
      @media screen and (max-width: 1200px) {
        margin-bottom: 2rem;
        margin-left: 1rem;
        margin-top: 1rem;
      }
    }
    .ilc-filter-toggle-container {
      width: 17rem;
    }

    .ilc-filter-toggle-button {
      background: white;
      padding: 0.6rem;
      position: relative;
      cursor: pointer;
      border: 1px solid #dedede;
      height: 38px;
      padding-left: 0.7rem;
      padding-right: 1.5rem;
      text-align: left;
      font-size: 0.8rem;
      color: inherit;
      border-radius: 5px;
    }
    .ilc-toggle-icon {
      top: 12px;
      right: 9px;
      svg {
        width: 15px;
        path {
          fill: #707070;
        }
      }
    }

    .ilc-input-container {
      @media screen and (min-width: 1201px) {
        width: 28rem;
      }
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 1rem;
      .ilc-searchbar-v2 {
        border-radius: 50px;
        height: 38px;
        padding: 1rem 1rem 1rem 2rem;
        text-align: left;
        font-size: 0.85rem;
      }
      .ilc-searchbar-icon {
        svg {
          width: 15px;
          font-weight: 700;
        }
        svg path {
          fill: #707070;
        }
      }
    }

    .ilc-searchbar-v2 {
      background: #fff;
      border: 1px solid #dedede;
      width: 100%;
      color: #8c8c8c;
      padding: 7px 0px 7px 2em;
      font-size: 1em;
      margin-bottom: 0px;
      height: 32px;
      line-height: normal;
      min-height: 0;
    }

    .ilc-agency-total-products {
      display: none;
    }

    .ilc-searchbar-icon {
      top: 12px;
    }
  }

  .ilc-filter-pills-container {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 55%;
    .ilc-product-filter-pill {
      background: #000;
      border: none;
      color: white;
      max-height: 16px;
      padding: 7px 12px;
      text-align: center;
      text-decoration: none;
      display: flex;
      align-items: center;
      margin: 4px 2px;
      border-radius: 16px;
      font-size: 0.6rem;
      margin-right: 0.5rem;
      .ilc-product-filter-pill-close {
        margin-left: 1rem;
        cursor: pointer;
        background: none;
        color: #fff;
        border: none;
        margin-bottom: 1px;
      }
    }
  }
}
