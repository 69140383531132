
.ilc-manufacturer-groups {
 display: flex; 
 flex-flow: column wrap; 
 width: 100%; 
 height: 2000px; 

 .ilc-manufacturer-group-by-letter {
   list-style: none;  
  .ilc-group-name {
    font-size: 1.5em;      
  }
   .ilc-manufacturer-list-entry {
     padding: 0.5em;
   }
 
 }

 @media screen and (max-width: 800px) {
   height: auto;

 }

}
