.ilc-product-search-page-heading {
  display: flex;
  padding: 20px 30px;
  margin-bottom: 23px;
  background: #f7f7f7;
  h2 {
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
  }
  p {
    font-size: 17px;
    margin-top: 0.6rem;
    line-height: normal;
  }
  .ilc-quick-search-filters {
    margin-left: auto;
    align-self: center;
    display: flex;
    align-items: center;
  }
}
