.ilc-project-templates {
  margin-right: 0;
  margin-left: 0;
  min-height: 400px;
  margin-bottom: 30px;
}
.ilc-project-templates > h2,
.ilc-project-templates h3 {
  font-size: 18px;
  margin-bottom: 0px;
}

.ilc-project-templates > h2 {
  clear: both;
}
.ilc-project-template-row {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  background-color: #f7f7f7;
}

.ilc-project-template-row:nth-child(even) {
  background-color: #bbbbbb;
}

.ilc-project-description {
  flex-basis: 70%;
  margin-right: 15px;
}

.ilc-project-description img {
  max-width: 40%;
  float: left;
  padding-right: 15px;
}

.ilc-project-template-info {
  margin-bottom: 30px;
}

.ilc-project-view-button {
  margin-left: auto;
  margin-right: 30px;
  margin-top: auto;
  margin-bottom: auto;
}

.ilc-project-view-button a {
  background-color: #fff;
  padding: 10px;
  border: solid 1px #bbbbbb;
}

@media screen and (max-width: 1000px) {
  .ilc-project-description img {
    float: none;
    max-width: 90%;
  }
}

.ilc-is-mobile .ilc-project-template-row {
  flex-flow: row wrap;
}

.ilc-is-mobile .ilc-project-view-button {
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.ilc-is-mobile .ilc-project-description {
  flex-basis: 100%;
}
