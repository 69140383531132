.ilc-quick-search-filters {
  align-self: flex-end;
  display: flex;

  select {
    margin-right: 1.5em;
    height: 45px;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 5px 5px 5px 15px;
    min-width: 275px;
  }
}
.ilc-search-results-v2 {
  .ilc-manufacturer-highlights-container {
    margin-bottom: 5rem;
  }
  .ilc-new-products-container {
    margin-bottom: 5rem;
  }
  .ilc-featured-products-container {
    margin-bottom: 5rem;
  }
  .new-products-heading,
  .featured-products-heading {
    margin-left: 0;
    font-size: 22px;
  }
  .ilc-attribute-checkbox-filters {
    width: 100%;
    border-bottom: solid 1px #dedede;
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;
    margin-left: 0;
    margin-right: 0;
    padding-top: 2.5%;
    display: flex;
    justify-content: left;

    label {
      padding-right: 1.5em;
    }
  }

  .ilc-quick-links {
    .ilc-attribute-checkbox-filters {
      margin-bottom: 0;
      padding-top: 0;
    }
    h5 {
      text-transform: capitalize;
      font-size: 1.2em;
      margin-bottom: 0.75em;
    }
  }

  @media screen and (max-width: 500px) {
    .ilc-attribute-checkbox-filters {
      flex-direction: column;
      padding-top: 8%;

      label {
        margin-bottom: 1em;
      }
    }
  }
  @media screen and (min-width: 501px) and (max-width: 999px) {
    .ilc-attribute-checkbox-filters {
      flex-direction: column;
      flex-wrap: wrap;
      padding-top: 5%;
      flex-basis: 32%;
      height: 360px;

      label {
        margin-bottom: 1em;
      }
    }
  }
  .ilc-checkboxes {
    margin-right: 0.5em;
  }

  .ilc-quick-search-products {
    min-height: unset;
    display: flex;

    .ilc-product-search-filter-value-container {
      display: none;
      &.active-accordion-container {
        display: block;
        .ilc-product-search-filter-form {
          display: flex;
          flex-direction: column;
          padding: 0.8rem 0.8rem;
          background: #f8f8f8;
          label {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
          }
          .ilc-product-search-filter-labels {
            font-size: 0.8rem;
          }
          .ilc-product-search-filter-count {
            font-size: 0.7rem;
            color: gray;
            margin-left: 0.2rem;
          }

          input {
            float: left;
            width: 1rem;
            margin-right: 3px;
          }
        }
      }
    }
    .ilc-product-search-filter-accordion {
      margin-bottom: 1rem;
      .ilc-filter-see-more {
        cursor: pointer;
        color: #6095cc;
        font-size: 0.9em;
        &:before {
          content: "";
          background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iU3ZnanNTdmcxMDAyIiB3aWR0aD0iMjg4IiBoZWlnaHQ9IjI4OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxuczpzdmdqcz0iaHR0cDovL3N2Z2pzLmNvbS9zdmdqcyI+PGRlZnMgaWQ9IlN2Z2pzRGVmczEwMDIiPjwvZGVmcz48ZyBpZD0iU3ZnanNHMTAwOCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwMCAxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB3aWR0aD0iMjg4IiBoZWlnaHQ9IjI4OCI+PHBhdGggZD0iTTUwLDcxLjVjLTEuNSwwLTIuOS0wLjUtNC4xLTEuNmwtMjkuNS0yN2MtMi40LTIuMi0yLjYtNi0wLjQtOC41YzIuMi0yLjQsNi0yLjYsOC41LTAuNEw1MCw1Ny40bDI1LjQtMjMuMwljMi40LTIuMiw2LjItMi4xLDguNSwwLjRzMi4xLDYuMi0wLjQsOC41bC0yOS41LDI3QzUyLjksNzEsNTEuNSw3MS41LDUwLDcxLjV6IiBmaWxsPSIjMDAwMDAwIiBjbGFzcz0iY29sb3IwMDAgc3ZnU2hhcGUiPjwvcGF0aD48L3N2Zz48L2c+PC9zdmc+Cg==");
          width: 1em;
          display: inline-block;
          height: 1em;
          vertical-align: middle;
          display: inline-block;
          background-size: contain;
          margin-right: 5px;
        }
        &.ilc-filter-active:before {
          transform: rotate(180deg);
        }
      }
      .ilc-product-search-filter-name-and-toggle {
        cursor: pointer;
        display: flex;
        align-items: center;
        background: #b8b8b8;
        color: white;
        padding: 0.7rem 0.8rem;
        border: 1px solid #c0c0c0;
        .ilc-product-search-filter-name {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 0.9rem;
        }
        .ilc-product-search-filter-toggle.active-accordion-toggle {
          margin-left: auto;
          font-size: 2rem;
          font-weight: 200;
          position: relative;
          right: 0;
          svg {
            transform: rotate(180deg);
          }
        }
        .ilc-product-search-filter-toggle {
          transform: rotate(180deg);
          margin-left: auto;
          font-size: 2rem;
          font-weight: 200;
          position: relative;
          right: 0;
          width: 1.3rem;
          height: 1.3rem;
          display: block;
          content: "I am dropdown arrow";
          svg {
            margin: auto;
            display: block;
          }
        }
      }
    }

    .ilc-product-list.sidebar-open {
      flex-basis: 83%;
      align-content: flex-start;
    }
  }

  .ilc-results-top {
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    margin-bottom: 1em;
    margin-top: 3rem;
    align-items: center;
    .ilc-result-counts {
      width: 100%;
    }
  }
}

.ilc-products-remaining-link {
  margin: auto;
  width: 100%;
  padding: 15px;
}

.ilc-products-remaining-link a {
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  display: block;
  margin: auto;
  width: 300px;
  max-width: 75%;
}
