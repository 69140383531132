.ilc-presentations {
  .ilc-product-tile {
    flex-basis: 20%;
    .ilc-description {
      line-height: 1.4em;
      height: 5em;
      overflow: hidden;
      font-style: normal;
    }

    .ilc-tag-list {
      span {
        border-radius: 10px;
        margin-right: 5px;
        background-color: #dedede;
        font-style: normal;
        padding: 2px 10px 2px 10px;
      }

      > span {
        display: inline-block;
        margin-right: 5px;
        margin-top: 7px;
      }
    }
  }

  .ilc-filters {
    margin-left: 0;
    margin-bottom: 1em;
    min-height: 3em;
    background-color: #dedede;
    border-radius: 5px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-flow: row no-wrap;
    align-items: center;
    & * {
      margin-left: 15px;
      flex-basis: 12%;
    }
    input[type="search"] {
      margin-left: auto;
      margin-right: 2em;
    }
  }
}
