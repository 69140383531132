.ilc-saved-items-body {
  margin-top: 1%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  .ilc-saved-items-page-header-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
    .ilc-saved-items-page-heading {
      font-size: 1.6rem;
      margin-bottom: 0.4rem;
      margin-top: 0px;
      svg {
        width: 23px;
        margin-right: 0.5rem;
      }
    }
    .ilc-page-action-buttons-container {
      margin-left: auto;

      .ilc-page-action-buttons {
        margin-right: 1rem;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 700;
        padding: 0.7rem 1.8rem;
        font-size: 0.7rem;
        border: none;
        color: #fff;
        cursor: pointer;
        line-height: normal;
        background: #000;
      }
    }
  }

  .ilc-saved-items-table {
    .ilc-saved-items-table-row-container .ilc-saved-items-table-value-box,
    .ilc-saved-items-table-header-container
      .ilc-saved-items-table-headers
      .ilc-saved-items-table-header-values {
      width: 34%;
    }
  }

  .ilc-product-info-cell {
    display: flex;
    width: 100%;
    .ilc-saved-items-table-product-image-container {
      position: relative;
      flex-basis: 35%;
      aspect-ratio: 1/1;
      .ilc-product-saved-items-image-link img {
        width: 100%;
        cursor: pointer;
      }
    }
    .ilc-saved-items-product-info-container {
      margin-left: 1.5rem;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      h4 {
        font-size: 14px;
        margin-top: 0;
      }
      h5 {
        font-size: 12px;
        line-height: 1.5;
        margin-top: 0;
      }
      a {
        margin-top: auto;
        cursor: pointer;
      }
      flex-basis: 65%;
    }
  }
}

.ng-modal-dialog {
  .ng-modal-dialog-content {
    .share-content {
      padding: 2rem 1.5rem;
      .share-heading {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        margin-top: 0;
      }
      .share-link-textbox {
        width: 65%;
        padding: 0.3rem;
        margin-bottom: 1.5rem;
      }
      .share-button {
        padding: 0.7rem 0.7rem;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        line-height: normal;
      }
    }
  }
}
