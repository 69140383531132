.ilc-application-gallery-tile {
  .app-gallery-tile-container {
    .app-gallery-image-container {
      .image-non-action {
        .photo-count-container {
          right: 70px;
          padding: 0.41em;
          svg {
            width: 19px;
            padding-right: 0.5em;
            color: white;
            path {
              fill: white;
            }
          }
        }
      }
      .overlay-action-buttons-container {
        .overlay-details-action-button {
          transform: rotate(0deg);
        }
      }
    }
    .app-gallery-name {
      margin: 0;
      margin-top: 0.3em !important;
      svg {
        margin-left: 0;
        margin-right: 2%;
        padding-right: 0.3em;
      }
    }
    .gallery-city-state {
      font-style: italic;
    }
    .app-gallery-contents-container {
      display: flex;
      margin-top: 0.3em;
      font-size: 14px;
      p {
        margin: 0;
        margin-right: 0.6em;
      }
    }
    .app-gallery-story {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
.ilc-is-mobile .ilc-application-gallery-tile {
  .app-gallery-contents-container {
    flex-direction: column;
    p {
      margin: 0;
      margin-top: 0.6em;
    }
  }
}