.ilc-product-comparison-page-container {
  width: 95%;
  margin-bottom: 8rem;

  .ilc-product-comparison-page-header-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5%;
    width: 90%;
    .ilc-page-title {
      font-size: 1.4rem;
      margin-bottom: 0;
    }
    .ilc-product-comparison-product-type {
      font-weight: normal;
      font-size: 1.2rem;
      margin-top: 0.5rem;
    }
    .ilc-page-action-buttons-container {
      margin-left: auto;

      .ilc-page-action-buttons {
        margin-top: 0.7rem;
        margin-bottom: 4rem;
        margin-right: 1rem;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 700;
        padding: 0.7rem 1.8rem;
        font-size: 0.7rem;
        border: none;
        color: #fff;
        cursor: pointer;
        line-height: normal;
        background: #000;
      }
    }
  }

  .ilc-product-comparison-main-body {
    margin-top: 1rem;
    .ilc-quick-search-products {
      min-height: unset;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      width: 90%;
      justify-content: center;
      margin-bottom: 1.5rem;
      .ilc-product-list {
        width: 100%;
        gap: 6%;
        margin-left: 14rem;
        flex-wrap: nowrap;
      }
      .ilc-product-compare-item {
        width: 20%;
        display: flex;
        flex-direction: column;
        .ilc-product-tile {
          min-width: 0;
          flex-basis: auto;
          max-width: none;
          margin-right: 0px;

          h4 {
            font-size: 1rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 45px;
            padding-bottom: 0;
          }
          p {
            height: 25px;
            margin-top: 0;
          }
          .ilc-product-description-container {
            height: 120px;
            .ilc-product-description {
              font-size: 0.9rem;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              padding-top: 0;
            }
          }
        }
        .ilc-action-link {
          display: flex;
          border: 1px solid #dedede;
          padding: 0.5rem;
          align-items: center;
          color: #808080;
          margin-bottom: 0.5rem;
          font-size: 0.9rem;
          cursor: pointer;
          top: 16rem;
          .ilc-action-icon {
            width: 17px;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }
      }
    }
    .ilc-compared-products-table {
      margin-bottom: 3rem;
      margin: 0 auto;
      width: 90%;
      .ilc-compared-products-table-row-container:nth-child(even) {
        background: #f1f1f1;
      }
      .ilc-compared-products-table-row-container:last-child {
        border-bottom: 1px solid #ebebeb;
      }
      .ilc-compared-products-table-row-container {
        border-top: 1px solid #ebebeb;
        .lex-cms-compare-products-table-attribute {
          font-size: 0.8rem;
          font-weight: bold;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          margin-left: 2rem;
          width: 10%;
        }
        .ilc-compared-products-table-row {
          display: flex;
          border-left: none;
          padding-left: 2rem;
          gap: 4%;

          .ilc-compared-products-table-values {
            width: 18%;
            font-size: 0.8rem;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.ng-modal-dialog {
  .ng-modal-dialog-content {
    .share-content {
      padding: 2rem 1.5rem;
      .share-heading {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        margin-top: 0;
      }
      .share-link-textbox {
        width: 65%;
        padding: 0.3rem;
        margin-bottom: 1.5rem;
      }
      .share-button {
        padding: 0.7rem 0.7rem;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        line-height: normal;
      }
    }
  }
}
