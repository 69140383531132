.ilc-application-gallery-landing {
  flex-basis: 75%;
  margin-top: 1em;
  .latest-galleries-tiles-container {
    .ilc-application-gallery-tile {
      flex-basis: 50%;
      .app-gallery-content {
        font-size: 13px;
      }
    }
  }
  .highlight-photos-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    h4 {
      margin: 0;
    }
    .highlight-application-photos {
      min-height: unset;
      display: flex;
      width: 100%;
      .highlight-images-container {
        display: flex;
        flex-flow: nowrap;
        gap: 50px 25px;
        padding: 20px;
        width: 95%;
        margin-bottom: 2rem;
        margin-left: 0;
        max-width: 96.5%;
        .ilc-application-photo {
          width: 31%;
          height: -moz-fit-content;
          height: fit-content;
          display: flex;
          flex-flow: column;
          min-width: unset;
          margin-bottom: unset;
          flex-basis: unset;
          .app-photo-image-container {
            position: relative;
            .app-photo-image {
              width: 100%;
            }
          }
        }
      }
    }
    .all-photo-link {
      font-weight: bold;
      color: #005993;
      cursor: pointer;
      font-size: .9rem;
      margin-left: 0;
    }
  }
}
.ilc-is-mobile .highlight-photos-container {
  .highlight-title {
    margin-bottom: 1em;
  }
  .highlight-application-photos {
    flex-direction: column;
    .highlight-title {
      margin-bottom: 1em;
    }
    .highlight-images-container {
      flex-direction: column;
      padding: 0;
      gap: 0;
      margin-bottom: 0;
      width: 100%;
      max-width: 100%;
      .ilc-application-photo {
        width: 100%;
      }
    }
  }
}