$ig-small-screen: 768px !default;
$ig-xsmall-screen: 480px !default;
$ig-white: #fff !default;
$ig-black: #000 !default;
$ig-blue: #005993 !default;
$ig-background-black: rgba(0, 0, 0, 0.4) !default;
$ig-transparent: rgba(0, 0, 0, 0) !default;
$ig-shadow: 0 2px 2px lighten($ig-black, 10%);

@mixin vendor-prefix($name, $value) {
  @each $vendor in ("-webkit-", "-moz-", "-ms-", "-o-", "") {
    #{$vendor}#{$name}: #{$value};
  }
}

// SVG ICON STYLES
.image-gallery-icon {
  color: $ig-white;
  transition: all 0.3s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow($ig-shadow);

  &:focus {
    // a11y support
    outline: 2px solid $ig-blue;
  }
}

.image-gallery-using-mouse {
  .image-gallery-icon {
    &:focus {
      outline: none;
    }
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  top: 0;
  padding: 20px;
  cursor: pointer;
  right: 0;

  .image-gallery-svg {
    height: 28px;
    width: 28px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
  display: none;
}

.image-gallery-play-button {
  left: 0;
  cursor: pointer;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);

  .image-gallery-svg {
    height: 50px;
    width: 25px;
  }

  &[disabled] {
    cursor: disabled;
    opacity: 0.6;
    pointer-events: none;
  }
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}
// End of Icon styles

.image-gallery {
  @include vendor-prefix("user-select", none);
  -webkit-tap-highlight-color: $ig-transparent;
  position: relative;
  background: $ig-white;
  display: flex;

  &.fullscreen-modal {
    background: $ig-white;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;

    .image-gallery-content {
      transform: translateY(-50%);
      background: $ig-white;
    }
  }
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 10%;
  background: $ig-white;
  display: flex;
  justify-content: center;
  flex: 1;

  &.fullscreen {
    .image-gallery-slide .image-gallery-image {
      height: calc(100vh - 80px); // 80 px for the thumbnail space
    }
    .image-gallery-slides {
      height: calc(75vh - 80px);
    }
    &.left,
    &.right {
      .image-gallery-slide .image-gallery-image {
        height: 100vh;
      }
    }
  }

  &.left,
  &.right {
    .image-gallery-slide .image-gallery-image {
      object-fit: contain;
    }
    .image-gallery-thumbnail {
      width: 80px;
    }
  }
}

.image-gallery-slide-wrapper {
  position: relative;
  flex: 1;
  &.left,
  &.right {
    display: inline-block;
    margin-left: 10px;
  }
  &.image-gallery-rtl {
    direction: rtl;
  }
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  .video-wrapper {
    width: 100%;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
    }
  }

  &.center {
    position: relative;
  }

  .image-gallery-image {
    width: 100%;
    object-fit: contain;
  }

  .image-gallery-description {
    background: $ig-background-black;
    bottom: 70px;
    color: $ig-white;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
    display: none;
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;

  .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid $ig-white;
    border-radius: 50%;
    box-shadow: $ig-shadow;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: all 0.2s ease-out;

    &:focus {
      transform: scale(1.2);
      background: $ig-blue;
      border: 1px solid $ig-blue;
    }

    &.active {
      transform: scale(1.2);
      border: 1px solid $ig-white;
      background: $ig-white;
    }
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;

  &.thumbnails-swipe-horizontal {
    touch-action: pan-y;
  }

  &.thumbnails-swipe-vertical {
    touch-action: pan-x;
  }

  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }
  &.left,
  &.right {
    display: inline-block;
    vertical-align: top;

    .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      padding: 0;
      display: inline-block;

      .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0;

        + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px;
        }
        .image-gallery-thumbnail-image {
          height: auto;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.image-gallery-thumbnails {
  padding: 5px 0;
  overflow: hidden;

  .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
  }
  .image-gallery-thumbnails-container > .image-gallery-thumbnail:only-child {
    display: none;
  }
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
  cursor: pointer;

  + .image-gallery-thumbnail {
    margin-left: 2px;
  }

  .image-gallery-thumbnail-inner {
    display: block;
    position: relative;
    overflow: hidden;
  }

  .image-gallery-thumbnail-image {
    vertical-align: middle;
    flex-basis: 100%;
    line-height: 0;
    height: 68px;
    object-fit: contain;
  }

  &.active,
  &:focus {
    outline: none;
    border: 1px solid #eeeeee;
    box-shadow: 2px 2px 10px #eeeeee;
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: $ig-shadow;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}

.image-gallery-index {
  background: $ig-background-black;
  color: $ig-white;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
