.ilc-application-photo, .ilc-application-gallery-tile {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  margin-bottom: 20px;
  flex-basis: 24%;
  @media screen and (max-width: 480px) {
    flex-basis: 100%;
    min-width: 320px;
  }
  @media screen and (max-width: 1112px) {
    min-width: 300px;
  }
  svg {
    fill: white;
    margin-left: 2%;
    width: 13px;
  }
  .favorite-pill {
    svg {
      fill: gold; 
    }

  }
  .app-photo-tile-container, .app-gallery-tile-container {
    position: relative;
    .app-photo-image-container, .app-gallery-image-container {
      position: relative;
      max-width: 100%;
      line-height: 1em;
      .app-photo-image, .app-gallery-image {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 2/1;
      }
      .overlay-pill {
        position: absolute;
        z-index: 1;
        color: white;
        padding: 2px 13px;
        background-color: black;
        border-radius: 17px;
        font-size: 10px;
      }
      .new-photo-pill {
        right: 2%;
        top: 3%;
      }
      .favorite-pill {
        top: 3%;
        left: 2%;
        display: flex;
        p {
          white-space: nowrap;
          padding: 0px;
          margin: 0px;
        }
      }
      .hot-spot-count-container, .photo-count-container {
        position: absolute;
        z-index: 1;
        color: white;
        font-size: 0.7rem;
        bottom: 13px;
        right: 5px;
        padding: 1px 6px;
        background-color: black;
        border-radius: 17px;
        display: flex;
        align-items: center;
        p {
          font-size: 0.7rem;
          margin: 0px;
          padding: 0px;
        }
        svg {
          width: 28px;
        }
      }
    }
    .overlay-action-buttons-container {
      position: absolute;
      z-index: 1;
      top: 40%;
      width: 100%;
      justify-content: center;
      gap: 4%;
      display: none;
      .overlay-action-button {
        display: flex;
        padding: 0.4rem;
        align-items: center;
        border: 0;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        background-color: #000000b0;
        &:hover {
          background-color: black;
        }
        svg {
          margin-right: 0.4em;
          fill: white;
          color: white;
          path {
            fill: white;
          }
        }
      }
      .overlay-details-action-button {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .app-gallery-image-hover-container, .app-photo-image-hover-container {
      .image-non-action {
        opacity: 0.4;
      }
      .overlay-action-buttons-container {
        display: flex;
      }
    }
  }

  .app-photo-uploader {
    font-style: italic;
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 3px;
  }
}
