#ilc-manufacturers {
 .contains-cadi-tiles {
   display: flex; 
   flex-flow: row wrap; 
   h3 {
     flex-basis: 100%; 
   }
 
   .ilc-product-cadi-tile {
     flex-basis: 45%; 
     padding: 1em; 

    .ilc-manufacturer-logo-wrapper {
      float: left; 
      width: 23%; 
      margin-left: 1%; 
      border: solid 1px #dedede; 

      .manufacturer-logo {
        height: auto; 

      }
      img {
        max-width: 100%; 
      }
         }
   .product-cadi-tiles {
      float: left; 
      width: 72%; 
      margin-left: 3%; 
      display: flex; 
      justify-content: space-between; 

      img {
        flex-basis: 22%;
        width: 22%; 
        margin-left: 2px; 
        border: solid 1px #dedede; 

      }
    }

    @media screen and (max-width: 767px) {
       flex-basis: 100%; 
       width: 100%;

    }
   }
 }
}

.ilc-is-mobile {
 
 #ilc-manufacturers {
   .contains-cadi-tiles {
    .ilc-product-cadi-tile {
      flex-basis: 100%; 
      width: 100%; 
      max-width: none !important; 
      min-width: none !important; 
     .product-cadi-tiles {
       width: 70%; 
       float: none;
       margin: auto;
       
     }
     .ilc-manufacturer-logo-wrapper {
       width: 70%;
       float: none; 
       margin: auto; 

     }
    }
   }
 }
}
