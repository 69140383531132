.ilc-calendar {
  .fc {
    margin-left: 0;
    width: 90%;
    .fc-content,
    .fc-list-item {
      cursor: pointer;
    }
  }

  .fc-unthemed td.fc-today {
    background-color: #ececec;
  }

  .ilc-project-bid-date-list-cell {
    display: inline-flex;

    & > div {
      flex-basis: 35%;
      margin-left: 7em;
    }
  }
  .ilc-event-details {
    padding: 2em;
    .ilc-event-header-wrapper {
      display: flex;
      flex-flow: row nowrap;
    }

    .ilc-event-image {
      width: 35%;
      img {
        max-width: 100%;
      }
    }
    h4 {
      background-color: #000;
      color: #fff;
      padding: 0.5em;
    }

    dl.ilc-event-header-left {
      width: 65%;
    }

    dl {
      clear: both;
      margin-left: 1em;
      .ilc-data-group {
        display: flex;
        margin-bottom: 1.5em;
        align-items: center;
        dt {
          width: 15%;
        }
        dd {
          margin-left: 0;
          width: 85%;
          a {
            word-break: break-all;
          }
          .ilc-company-logo {
            vertical-align: middle;
            width: 40%;
            margin-right: 15px;
            height: auto;
          }
        }
      }
    }
  }
}
