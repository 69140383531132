.ilc-is-mobile .ilc-application-gallery {
  flex-direction: column;
  .ilc-search-container {
    margin-right: 0;
  }
  .ilc-application-photo {
    width: 100%;
    margin-bottom: 20px;
  }
}
.ilc-application-gallery, .ilc-application-gallery-page {
  display: flex;
  .ilc-search-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    margin-right: 1rem;
    flex-basis: 20%;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .ilc-inspirational-search-text-container {
      padding-bottom: 0;
      width: unset;
      margin: unset;
      padding: unset;
      h1 {
        margin: 0;
      }
    }
    .ilc-photo-gallery-toggle {
      display: flex;
      flex-direction: column;
      .toggle-radio-container {
        display: flex;
        margin-top: 0.25em;
        label {
          display: flex;
          svg {
            width: 20px;
            margin-left: 0.5em;
            margin-right: 0.5em;
          }
        }
      }
      .toggle-radio-container:last-child {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .ilc-show-all-galleries-photos-button {
      display: flex;
      background-color: #000;
      color: #fff;
      align-items: center;
      border: none;
      padding: 0.5em;
      padding-left: 1em;
      padding-right: 2em;
      border-radius: 3px;
      cursor: pointer;
      margin-top: 12px;
      svg {
        width: 20px;
        transform: rotate(180deg);
        margin-left: 0.5em;
        margin-right: 0.5em;
        path {
          fill: #fff;
        }
      }
    }
    .ilc-inspirational-search-form-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1em;
      @media screen and (max-width: 480px) {
        align-items: flex-start;
        flex-direction: column;
      }
      .app-gallery-filter-toggle-container {
        border-radius: 50px 0 0 50px;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        .app-gallery-filter-text {
          font-style: italic;
        }
        .app-gallery-filter-toggle-button {
          background: #fff;
          padding: 0.6rem;
          position: relative;
          cursor: pointer;
          border: 1px solid #dedede;
          height: 38px;
          padding-left: 0.7rem;
          padding-right: 1.5rem;
          text-align: left;
          text-transform: capitalize;
          line-height: normal;
          font-size: .8rem;
          color: inherit;
          border-radius: 5px;
        }

        .ilc-reset-selections {
          margin-top: 1em; 
          background: #000; 
          color: #fff; 
          padding: 0.5em;
          cursor: pointer;


        }
        .filter-button-disabled {
          background: #f7f7f7;;
        }
      }
    }
  }
  .app-type-photos-container, .app-gallery-container, .gallery-right-container {
    flex-basis: 80%;
    margin-top: 1em;
    .ilc-filters-container {
      margin-top: 0em;
      margin-bottom: 2em;
      @media screen and (max-width: 1080px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .query-text {
        font-weight: bold;
        margin: 0px;
        span {
          font-weight: normal;
        }
      }
      .ilc-query-container {
        display: flex;
        margin-bottom: 10px;
        @media screen and (min-width: 400px) and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }
        @media screen and (max-width: 400px) {
          flex-direction: column;
          gap: 5px;
          width: 100%;
        }
        .query-text {
          font-size: 16px;
          margin-right: 10px;
          font-weight: bold;
        }
        .filter-pills-container {
          display: flex;
          .filter-pill {
            display: flex;
            margin-right: 1em;
            .remove-filter-icon {
              color: white;
              background-color: #666666;
              padding-left: 0.25em;
              padding-right: 0.25em;
              margin-left: 0.25em;
              cursor: pointer;
            }
          }
        }
        .ilc-clear-photos {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 0.9em;
          padding: 0;
          color: #333;
          margin-left: 1em;
        }
        @media screen and (max-width: 480px) {
          .ilc-clear-photos {
            margin-top: 1rem;
            padding-left: 0;
            margin-left: 0;
          }
        }
      }
      .ilc-sort-container {
        display: flex;
        flex-flow: row wrap;
        @media screen and (min-width: 480px) and (max-width: 767px) {
          width: 100%;
        }
        @media screen and (max-width: 480px) {
          flex-direction: column;
          gap: 1%;
          width: 100%;
        }
        .ilc-filter-input {
          font-size: 12px;
          color: #666666;
          min-width: 140px;
          padding-left: 10px;
          padding-right: 10px;
          height: 35px;
          appearance: none;
          margin-right: 0.75em;
          border-radius: 5px;
          border-color: #dedede;
          cursor: pointer;
          @media screen and (max-width: 767px) {
            margin-bottom: 1%;
          }
          @media screen and (min-width: 480px) and (max-width: 767px) {
            flex-basis: 46%;
          }
        }
      }
    }

    .ilc-result-container {
      margin-bottom: 0px;
      margin-top: 25px;
      .ilc-product-list {
        display: flex;
      }
      .ilc-product-list .ilc-product-tile {
        flex-basis: 20%;
        max-width: 200px; 
        background: #fff; 
        a {
          display: block;  
        }
        .ilc-product-img img {
          width: 100%
        }
        
        .ilc-product-description {
         padding: 0px; 
         height: 4.2em;  

        }
      }
      .app-photo-list, .app-gallery-list  {
        align-content: flex-start;
        gap: 1%;
        display: flex;
        flex-flow: row wrap;
        margin-left: 0;
      }
    }

    .photos-remaining-link, .galleries-remaining-link {
      margin: auto;
      width: 100%;
      padding: 15px;
      a {
        border: 1px solid #000;
        padding: 10px;
        text-align: center;
        display: block;
        margin: auto;
        width: 300px;
        max-width: 75%;
      }
    }
  }
}
.ilc-lightbox {
  z-index: 100000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  .ilc-lightbox-image-container {
    position: relative;
    z-index: 4;
    width: 91%;
    display: flex;
    align-items: center;
    justify-content: center;
    .ilc-lightbox-img {
      width: 100%;
      height: 95vh;
      object-fit: contain;
    }
    .ilc-lightbox-nav-button {
      background-color: rgba(0,0,0,1);
      color: white;
      border: none;
      cursor: pointer;
      padding: 0.8rem;
      position: absolute;
      top: 50%;
      bottom: 50%;
      display: flex;
      align-items: center;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-radius: 5px;
    }

    .ilc-lightbox-prev-button {
      left: 1%;
    }

    .ilc-lightbox-next-button {
      right: 1%;
    }
  }
  .ilc-lightbox-close {
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    color: white;
    border: none;
    border-radius: 50%;
    top: 1%;
    right: 1%;
    cursor: pointer;
    font-size: 20px;
    padding: 11px 18px;
  }
}