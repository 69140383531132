.ilc-is-mobile {
  .ilc-application-gallery-page {
    flex-direction: column;
    .gallery-left-container {
      margin-right: 0;
      margin-bottom: 1em;
    }
    .gallery-right-container {
      .app-gallery-meta-container {
        flex-direction: column;
        .app-gallery-contents-toggle-container {
          flex-direction: column;
          margin-bottom: 0.5em;
          .app-gallery-filter-text {
            margin-right: 0;
            margin-bottom: 0.3em;
          }
          .app-gallery-contents-toggle {
            width: 100%;
          }
        }
        .action-buttons-container {
          width: 100%;
          .ilc-action-link {
            width: 100%;
          }
        }
      }
      .app-gallery-contents-container {
        margin-top: 1.5em;
        .ilc-product-list {
          flex-wrap: wrap;
          .ilc-product-tile {
            flex-basis: 47%;
            margin-bottom: 1em;
          }
        }
      }
    }
  }
}
.ilc-application-gallery-page {
  display: flex;
  .gallery-left-container {
    flex-basis: 25%;
    margin-right: 5em;
    .details-container {
      margin-top: 0;
      .details-block {
        .gallery-details-manufacturers-heading {
          margin-bottom: 0.1em;
        }
        .gallery-details-subheading {
          font-size: 14px;
          margin: 0;
          margin-bottom: 5px;
          line-height: 1.35em;
        }
        .gallery-details-content-item {
          margin: 0;
          margin-top: 0.1em;
        }
        .gallery-city-state {
          font-style: italic;
        }
      }
    }
  }
  .gallery-right-container {
    flex-basis: 75%;
    margin-top: 0;
    .app-gallery-meta-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .app-gallery-contents-toggle-container {
        display: flex;
        align-items: center;
        .app-gallery-filter-text {
          margin: 0;
          margin-right: 0.5em;
          color: #808080;
        }
        .app-gallery-contents-toggle {
          background: #fff;
          position: relative;
          cursor: pointer;
          border: 1px solid #dedede;
          padding: 0.5rem;
          padding-left: 0.7rem;
          padding-right: 1.5rem;
          text-align: left;
          line-height: normal;
          font-size: .8rem;
          color: inherit;
          border-radius: 5px;
          appearance: auto !important;
          -webkit-appearance: auto !important;
        }
      }
      .action-buttons-container {
        width: unset;
        .ilc-action-link {
          width: 9rem;
          display: flex;
          justify-content: center;
          margin-bottom: 0;
          padding: 0.3rem;
          border-radius: 2px;
          padding-right: 1rem;
          svg {
            width: 20px;
            padding-left: unset;
            padding-right: 0.25rem;
          }
        }
      }
    }
    .app-gallery-contents-container {
      margin-left: 0;
      margin-top: 1em;
      .content-products {
        gap: 1.5%;
      }
    }
  }
}