.ilc-resources-modal-overlay {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 500; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  transition: 0.5s;
  .ilc-resources-modal {
    position: absolute;
    margin: 5% auto;
    left: 0;
    right: 0;
    width: 65%;
    overflow: auto;
    background: white;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    top: 12%;
    height: 65vh;
    border-radius: 25px;
    .ilc-resources-modal-header {
      padding: 1rem;
      .ilc-resources-modal-close {
        float: right;
        font-size: 0.9rem;
        font-weight: bold;
      }
      .ilc-resources-modal-close:hover,
      .ilc-resources-modal-close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .ilc-resources-modal-content {
      background: white;
      padding: 1.5rem;
      h3 {
        font-size: 24px;
        margin-bottom: 0.5rem;
        margin-top: 0;
        font-weight: normal;
      }
      .ilc-product-download-list {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        .ilc-product-download-filename {
          width: 30%;
          margin-bottom: 0.5rem;
          margin-right: 1.5rem;
          font-size: 1rem;
        }
      }
    }
  }
}
