.ilc-right-product-samples .ng-modal {
  overflow: hidden !important;
}

.ilc-sample-request-form {
  min-height: 300px;
  display: flex;
  flex-flow: column;
  width: 90%;
  margin: auto;
}

.ilc-right-product-samples h3 {
  margin-left: 0;
}

.ilc-sample-request-form input {
  height: 20px;
  padding: 0.5em;
  display: block;
  width: 100%;
}

.ilc-sample-request-form textarea {
  width: 100%;
}

.ilc-sample-request-form label {
  display: block;
}

.ilc-form-group {
  width: 90%;
  margin: auto;
  display: flex;
}

.ilc-col {
  flex-basis: 100%;
  margin: 1em;
}

.ilc-sample-request-form .ilc-button {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  width: 100px;
}

.ilc-button {
  text-decoration: none;
  border: solid 1px #dedede;
  text-align: center;
  padding: 0.5em;
}

.ilc-has-error {
  padding-top: 5px;
  color: red;
}

.ilc-sample-request-link {
  display: block;
  margin-top: 1em;
  padding: 10px;
}

.ilc-sample-filters {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-right: 6.5%;
  margin-bottom: 1em;
}

.ilc-sample-filters select {
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}
