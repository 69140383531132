.ilc-product-page-body > * {
  min-width: 0px;
}

.ilc-product-page-body {
  display: grid;
  grid-template:
    "main sidebar"
    "similar sidebar";

  .ilc-main-product-info-container {
    display: flex;
    grid-area: main;
    margin-right: 3rem;
    padding: 20px;
    .ilc-product-details-container {
      width: 60%;
      background: #f7f7f7;
      border-radius: 5px;
      padding: 2rem;
      .ilc-manufacturer-logo {
        width: 40%;
        background: #fff;
        margin-bottom: 1rem;
        cursor: pointer;
      }

      .ilc-product-name {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        margin-top: 0px;
      }
      .ilc-product-subname {
        font-size: 0.9rem;
        font-weight: normal;
      }
      .ilc-product-description {
        font-size: 1rem;
        word-break: break-word;
      }
    }
  }

  .ilc-product-image-gallery-container {
    margin-right: 2rem;
    width: 40%;
  }
}
.ilc-similar-products-container {
  margin-bottom: 5rem;
  grid-area: similar;
  margin-top: 3rem;
  width: 92%;

  .ilc-similar-products-heading {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
    margin-bottom: 0.7rem;
  }
  .ilc-quick-search-products {
    margin-bottom: 0px;
    min-height: unset;

    .ilc-product-list {
      margin-left: 1rem;
      .ilc-product-tile {
        flex-basis: 18%;
        min-width: 0;
        width: auto;
        h4 {
          font-size: 1rem;
        }
        .ilc-product-description {
          font-size: 0.9rem;
        }
      }
    }
  }
  .ilc-product-similar-collection-all-link {
    font-weight: bold;
    font-size: 0.9rem;
  }
}

.ilc-product-sidebar-container {
  grid-area: sidebar;
  width: 18rem;
  h4 {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 0.9rem;
    color: #707070;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ilc-action-link {
    display: flex;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 0.5rem;
    align-items: center;
    color: #808080;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    margin-left: 15px;
    cursor: pointer;
    .ilc-action-icon {
      width: 15px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  .ilc-product-resources-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    span {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;
    }
    ul {
      list-style-type: none;
      margin-top: 0;
      padding: 0;
      font-size: 0.9rem;
      color: #707070;
      margin-left: 15px;
      .ilc-product-download-view-all-section {
        cursor: pointer;
        margin-left: auto;
        display: flex;
        font-size: 0.8rem;
        align-items: center;
        .ilc-product-download-view-all-count {
          margin-left: 0.2rem;
        }
      }

      li {
        word-wrap: break-word;
        margin-bottom: 0.5rem;
      }
    }
    small {
      color: #707070;
      font-size: 0.8rem;
    }
  }
  .ilc-product-technical-specs-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    ul {
      list-style-type: none;
      margin-top: 0;
      padding: 0;
      font-size: 0.9rem;
      color: #707070;
      margin-left: 15px;
      li {
        margin-bottom: 0.8rem;
      }
    }
    small {
      font-size: 0.8rem;
      color: #707070;
    }
  }
}

.ng-modal {
  .ng-modal-dialog {
    .ng-modal-dialog-content {
      .share-content {
        padding: 2rem 1.5rem;
        .share-heading {
          font-size: 1.4rem;
          margin-bottom: 0.5rem;
          margin-top: 0;
        }
        .share-link-textbox {
          width: 65%;
          padding: 0.3rem;
          margin-bottom: 1.5rem;
        }
        .share-button {
          padding: 0.7rem 0.7rem;
          text-align: center;
          text-decoration: none;
          cursor: pointer;
          line-height: normal;
        }
      }
    }
  }
}
