.ilc-icon {
  transform: rotate(180deg);
}

.ilc-course-left-text .ilc-icon {
  width: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.ilc-resource__closed {
  opacity: 0;
  width: 0px;
  height: 0px;
  overflow: hidden;
  margin: 0;
}

.ilc-resource__open {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.ilc-continuing-education-banner {
  background-color: #363636;
  color: #fff;
  font-size: 0.95em;
}

:not(.ilc-is-mobile).ilc-sidebar__open .ilc-continuing-education-banner {
  margin-right: 30%;
}

.ilc-continuing-education-banner .ilc-course-banner-inner {
  margin-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.ilc-continuing-education-banner .ilc-course-banner-inner div {
  display: inline-block;
  vertical-align: middle;
}
.ilc-continuing-education-banner .ilc-course-description {
  border-left: 2px solid #fff;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ilc-continuing-education-banner .ilc-course-description p {
  margin-bottom: 0px;
  padding-left: 10px;
}
.ilc-continuing-education-banner .ilc-course-description p:first-child {
  font-weight: bold;
}
.ilc-continuing-education-banner .ilc-course-description p + p {
  font-size: 12px;
  margin-top: -2px;
}
.ilc-continuing-education-banner .ilc-course-description + a {
  background-color: #f1f1f1;
  margin-left: 3em;
  padding: 5px;
  border-radius: 2px;
  padding-left: 0.5em;
  color: #000;
}

.ilc-continuing-education-banner .ilc-course-description + a:hover {
  color: initial;
  background-color: #ffffff;
}

@media screen and (max-width: 1055px) {
  .ilc-continuing-education-banner .ilc-course-banner-inner {
    margin: auto;
    text-align: center;
  }

  .ilc-continuing-education-banner .ilc-course-description {
    border: none;
  }

  .ilc-continuing-education-banner .ilc-course-banner-inner a {
    display: block;
    margin: auto;
    width: 150px;
    text-align: center;
    margin-bottom: 5px;
  }
}

/*RESOURCE TOOLBAR STYLES */

.ilc-resource-view-toggles {
  display: inline-block;
}

.ilc-resource-view-toggles a {
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 1px;
  padding-bottom: 5px;
}

.ilc-resource-view-toggles a.ilc-active {
  border-bottom: 1px solid #000;
}

/*Continuing Education Tiles */

.ilc-continuing-education-courses {
  display: flex;
  flex-flow: row wrap;
  margin-left: 0;
  min-height: 400px;
  margin-bottom: 15px;
  width: 100%;
  margin-right: 0;
}
.ilc-continuing-education-courses .ilc-course-tile {
  flex-basis: 31%;
  margin-right: 15px;
  max-width: 31%;
}

.ilc-continuing-education-courses .ilc-course-img {
  max-width: 100%;
  border: solid 1px #dedede;
}
.ilc-continuing-education-courses .ilc-course-img img {
  width: 100%;
}

.ilc-continuing-education-courses .ilc-course-description {
  line-height: 1.4em;
  max-height: 4.2em;
  overflow: hidden;
  position: relative;
}

.ilc-continuing-education-courses .ilc-course-description:after {
  height: 1.4em;
  text-align: right;
  content: "";
  width: 15%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 80%
  );
}

a:hover .ilc-course-description {
  color: initial;
}

@media screen and (min-width: 1700px) {
  .ilc-continuing-education-courses .ilc-course-tile {
    flex-basis: 22%;
    margin-right: 30px;
    max-width: 22%;
  }
}

.ilc-continuing-education-courses .ilc-courses-remaining-link {
  margin: auto;
  width: 100%;
  padding: 15px;
}
.ilc-continuing-education-courses .ilc-courses-remaining-link a {
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  display: block;
  width: 300px;
  max-width: 75%;
  margin: auto;
}

.ilc-is-mobile .ilc-login-button {
  position: static;
}

.ilc-no-resource .ilc-icon {
  width: 75px;
  display: block;
  margin: auto;
}

.ilc-no-resource .ilc-icon svg path,
.ilc-resource-view-toggles .ilc-icon svg path,
.ilc-login-button .ilc-icon svg path {
  fill: #bbb;
}

.ilc-no-resource {
  align-self: center;
  margin: auto;
  text-align: center;
  padding: 100px;
}
