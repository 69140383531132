/*Product Tiles */

.ilc-quick-search-box {
  display: flex;
  flex-flow: column wrap;
  width: 90%;
  margin-left: 0;
  align-items: center;
  margin-bottom: 30px;
  color: #fff;
  background-color: #353535;
  padding: 20px;
}

.ilc-is-mobile .ilc-quick-search-box {
  width: 100%;
  margin: 0;
  padding: 0;
}

.ilc-quick-search-box > p {
  padding: 25px;
  min-width: 150x;
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}

.ilc-quick-search-box > input {
  width: 50%;
  min-width: 150px;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .ilc-quick-search-box {
    margin-left: auto;
  }
  .ilc-quick-search-box > input {
    width: 80%;
  }
}

.ilc-quick-search-products {
  min-height: 400px;
  margin-bottom: 40px;
  width: 100%;
}

.ilc-quick-search-products .ilc-product-list {
  display: flex;
  flex-flow: row wrap;
  margin-left: 0;
}
.ilc-quick-search-products .ilc-product-tile {
  flex-basis: 12%;
  margin-right: 15px;
  min-width: 190px;
  border: solid 1px #dedede;
  font-size: 1.1em;
  margin-bottom: 20px;
  .new-product-pill {
    position: absolute;
    color: white;
    padding: 4px 13px;
    background-color: #87c401;
    border-radius: 17px;
    font-size: 11px;
    right: 5%;
    top: 5%;
  }
  .ilc-product-actions {
    display: flex;
    border-bottom: solid 1px #dedede;
  }
  .ilc-cart-button {
    display: flex;
    font-size: 0.8rem;
    width: 50%;
    align-items: center;
    background: #000;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    svg {
      width: 15px;
      margin-right: 5px;
      margin-left: 8px;
    }
  }
  .ilc-cart-button.ilc-item-added {
    background: white;
    color: inherit;
  }
  .ilc-compare-button {
    display: flex;
    font-size: 0.8rem;
    width: 50%;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    svg {
      width: 17px;
      margin-right: 1px;
      margin-left: 10px;
      margin-top: 2px;
    }
  }
  .ilc-compare-button.ilc-item-added {
    background: #000;
    color: white;
  }
  .ilc-compare-button:hover {
    background: #000;
    color: white;
  }
  .ilc-cart-button:hover {
    background: #000;
    color: white;
  }
  .ilc-compare-button svg g {
    fill: inherit;
  }
  .ilc-compare-button:hover svg g {
    fill: white;
  }
  .ilc-compare-button.ilc-item-added svg g {
    fill: white;
  }
}

.ilc-quick-search-products .ilc-product-tile h4 {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 0.7em;
  line-height: 20px;
  overflow: hidden;
  position: relative;
}

.ilc-quick-search-products .ilc-product-tile h4:after {
  height: 14px;
  text-align: right;
  content: "";
  width: 15%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 80%
  );
}

.ilc-quick-search-products .ilc-product-tile p {
  padding: 0.7em;
  font-style: italic;
  font-size: 12px;
  margin-bottom: 0px;
}

.ilc-quick-search-products .ilc-product-img {
  max-width: 100%;
  position: relative;
}
.ilc-quick-search-products .ilc-product-img img {
  width: 100%;
}

@media screen and (min-width: 1700px) {
  .ilc-quick-search-products .ilc-product-tile {
    flex-basis: 12%;
    margin-right: 30px;
    max-width: 12%;
  }
}

.ilc-quick-search-products .ilc-products-remaining-link {
  margin: auto;
  width: 100%;
  padding: 15px;
}
.ilc-quick-search-products .ilc-products-remaining-link a {
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  display: block;
  margin: auto;
  width: 300px;
  max-width: 75%;
}

.ilc-quick-search-products .ilc-products-remaining-link a:hover {
  cursor: pointer;
}

.ilc-clear-products {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: 0;
  color: #333;
  font-size: 13px;
  margin-left: 15px;
  width: 25%;
}
