.ilc-is-mobile .ilc-tag-tabs li {
  width: 100%;
  text-align: center;
  margin-bottom: 10px !important;
  text-align: right;
}

.ilc-is-mobile
  #ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .manufacturer-logo,
.ilc-is-mobile
  #other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .manufacturer-logo {
  height: auto;
  padding: 15px;
}

.ilc-is-mobile
  #ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .manufacturer-description,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .manufacturer-description {
  strong {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.ilc-is-mobile .ilc-header {
  margin-bottom: 15px;
}

.content .ilc-is-mobile .ilc-tag-tabs,
.ilc-is-mobile .ilc-tag-tabs {
  margin: auto;
  display: grid;
  grid-template-columns: 25% 75%;
}
.ilc-is-mobile
  .ilc-manufacturer-dialog-tab-row
  .ilc-manufacturer-dialog-tabs
  li {
  width: 200px;
}

.ilc-manufacturer-dialog-tab-row .ilc-mobile-collapsible span {
  color: white;
  margin-right: 5px;
}

.ilc-is-mobile .ilc-tag-tabs li a {
  background-color: transparent;
  color: black;
}

.ilc-is-mobile li#ilc-manufacturers-tab.active {
  grid-column: 1 / span 2;
  text-align: center;
}

.ilc-manufacturer-dialog-tab-row .ilc-mobile-collapsible span {
  color: #fff;
  margin-right: 5px;
  font-size: 2em;
  width: 50px;
  text-align: right;
  padding: 5px;
}

.ilc-header .ilc-mobile-collapsible {
  padding: 10px 5px 10px 5px;
  overflow: hidden;
  margin: auto;
}

.ilc-is-mobile .ilc-manufacturer-dialog-tab-row {
  padding: 0px;
}

.ilc-is-mobile .active-tab {
  color: white;
  padding: 10px;
  background-color: #403e3e;
  text-align: center;
  margin-left: -15px;
  margin-bottom: 10px;
  margin-top: -3px;
}
.ilc-is-mobile .ng-modal-dialog {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  overflow-y: scroll;
  width: 90%;
  height: 90%;
}

.ilc-is-mobile .ng-modal {
  overflow-y: hidden;
}

@media screen and (min-width: 700px) {
  .ilc-is-mobile .ng-modal {
    overflow-y: hidden;
  }
  .ilc-is-mobile .ng-modal-dialog {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 90%;
    height: 90%;
    overflow-y: scroll;
  }
}

.ilc-is-mobile .ng-modal-dialog-content {
  width: 100%;
}

.ilc-is-mobile
  .ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-literature
  li {
  margin-left: 0px;
  padding-right: 5px;
}

.ilc-is-mobile
  .ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-literature
  ul
  li:nth-child(1) {
  margin-left: 0px;
}

.ilc-is-mobile
  .ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-literature
  ul
  li
  img {
  max-width: 150px;
}

.ilc-is-mobile.ilc-sidebar__closed .ilc-sidebar-items {
  display: block;
}
.ilc-agency-pick.ilc-agency-pick__open.ilc-tags-list.ilc-mobile-tag-list-collapsed {
  padding-bottom: 0;
}
.ilc-is-mobile.ilc-sidebar__open .ilc-header {
  margin-right: 0;
}
.ilc-is-mobile.ilc-sidebar__open .ilc-body {
  margin-right: 0;
}

.ilc-is-mobile .ilc-header .ilc-tags-list {
  height: 100%;
}
.ilc-is-mobile .ilc-header .ilc-mobile-collapsible {
  color: #3a3a3a;
  border: solid 1px #3a3a3a1c;
}

.ilc-mobile-collapsible span {
  float: right;
}

.ilc-is-mobile.ilc-sidebar__open .ilc-sidebar-body {
  padding-top: 0px;
  max-height: 400px;
  overflow-y: scroll;
  background-color: #353535;
  box-shadow: -3px 0px 8px #a0a0a0;
  padding: 5px;
  margin: auto;
}

.ilc-is-mobile.ilc-sidebar__closed .ilc-sidebar-body {
  max-height: 0px;
  overflow: hidden;
  display: block;
}

.ilc-is-mobile.ilc-sidebar__open .ilc-sidebar-items {
  display: block;
}

.ilc-is-mobile .ilc-sidebar-items img {
  width: 75px;
}

.ilc-is-mobile .ilc-header.ilc-left {
  float: none;
  margin: auto;
  width: 95%;
}

.ilc-mobile-tag-list {
  max-height: 1000px;
}

.ilc-is-mobile .ilc-tags-list {
  transition: all 0.5s;
  margin: auto;
  overflow: scroll;
}

.ilc-tags-list.ilc-mobile-tag-list-collapsed {
  max-height: 0;
  overflow: hidden;
  display: block;
}

.ilc-is-mobile .ilc-tags-list span {
  float: right;
  margin-right: 15px;
}

.ilc-is-mobile .ilc-header__bin-header {
  margin-bottom: 0px !important;
}

.ilc-is-mobile .ilc-body {
  width: 95%;
  margin: auto;
}

.ilc-is-mobile ul.ilc-tag-tabs {
  display: relative;
  position: relative;
}

.ilc-is-mobile li#ilc-manufacturers-tab:not(.active) {
  padding: 0px;
  text-align: left;
  border-radius: 2px;
}

.ilc-is-mobile li#ilc-manufacturers-tab:not(.active) a {
  background-color: #efefef;
  color: #000;
}

.ilc-mobile-collapsible span {
  float: right;
}

.ilc-is-mobile .ilc-sidebar-body {
  position: static;
}

.ilc-is-mobile.ilc-sidebar__open .ilc-sidebar-body {
  padding-top: 0px;
  max-height: 400px;
  overflow: scroll;
}

.ilc-is-mobile.ilc-sidebar__closed .ilc-sidebar-body {
  max-height: 0px;
  overflow: hidden;
  display: block;
}

.ilc-is-mobile .ilc-sidebar-body {
  transition: max-height 0.5s;
}

.ilc-is-mobile .ilc-sidebar-items {
  display: block;
  margin-top: 10px;
}

.ilc-is-mobile .ilc-sidebar-items img {
  width: 75px;
}

.ilc-is-mobile .manufacturers-list {
  position: relative;
  display: flex;
  flex-flow: row wrap;
}

.ilc-is-mobile
  #ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list,
#other-manufacturers .ilc-manufacturer-container .manufacturers-list {
  padding: 15px 0px 0px 0px;
  position: relative;
  margin-top: 10px;
  clear: left;
  @media screen and (min-width: 700px) {
    clear: none;
  }
}

.ilc-is-mobile .ilc-manufacturers__sort {
  position: absolute;
  top: 0;
}

.ilc-is-mobile
  #ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  img,
▫
  #other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  img {
  max-width: 100%;
}

.ilc-is-mobile .ilc-manufacturers__sort {
  position: absolute;
  top: 0;
}

.ilc-is-mobile
  #ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer,
▫
  .ilc-is-mobile
  #other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer {
  max-width: 39%;
  min-width: 39%;
  margin-left: 5%;
  margin-right: 5%;
}

@media screen and (min-width: 700px) {
  .ilc-is-mobile
    #ilc-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list
    .ilc-manufacturer,
  ▫
    .ilc-is-mobile
    #other-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list
    .ilc-manufacturer {
    margin-right: 0;
    min-width: 22%;
  }
}

.ilc-is-mobile .manufacturers-list {
  position: relative;
}

.ilc-agency-pick.ilc-agency-pick__closed.ilc-tags-list.ilc-mobile-tag-list-collapsed {
  padding-bottom: 0;
}

.ilc-is-mobile .ilc-manufacturer-container {
  flex-direction: column;
  .ilc-manufacturer-container-side {
    width: 100%;
    margin-left: 0;
    @media screen and (min-width: 700px) {
      width: 40%;
    }
  }
  .manufacturers-list h3 {
    display: none;
  }
}
.ilc-mobile-agency-pick-open.is--collapsed {
  color: #cccaba;
}

.ilc-is-mobile .ilc-agency-pick-body {
  width: 100%;
}

.ilc-mobile-agency-pick-open {
  color: #dfcd00;
  position: absolute;
  right: 15px;
  font-size: 2em;
  top: 10px;
}

.ilc-agency-pick.ilc-agency-pick__closed.ilc-tags-list.ilc-mobile-tag-list-collapsed {
  padding-bottom: 0;
  font-size: 2em;
  top: 10px;
  color: #121310;
  margin-top: 32px;
}

.ilc-is-mobile .ilc-agency-pick {
  margin-top: 40px;
}

.ilc-is-mobile .ilc-agency-pick-products {
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  width: 90vw;
}

.ilc-is-mobile .ilc-agency-pick-products li {
  display: inline-block;
  float: none;
  margin-top: 15px;
}

.ilc-is-mobile .ilc-manufacturer-products-pager-container {
  text-align: center;
}
.ilc-is-mobile
  .ilc-manufacturer-products-pager-container
  .ilc-manufacturer-products-pager
  li {
  width: 30px;
  height: 30px;
}

.ilc-is-mobile
  .ilc-manufacturer-products-pager-container
  .ilc-manufacturer-products-pager
  li.active {
  width: 30px;
  height: 30px;
}
.ilc-mobile-manufacturers-container {
  margin-top: 30px;
}

/*Continuing Education Mobile Styles */

.ilc-is-mobile .ilc-continuing-education-courses {
  display: block;
}

.ilc-is-mobile .ilc-continuing-education-courses .ilc-course-tile {
  max-width: 100%;
}

.ilc-is-mobile .ilc-resource-view-toggles a.ilc-active {
  border: solid 1px #dedede;
  color: #000;
}

.ilc-is-mobile .ilc-resource-view-toggles a.ilc-active svg path {
  fill: #000;
}

@media screen and (max-width: 700px) and (orientation: portrait) {
  .ilc-is-mobile a.ilc-clear-all,
  .ilc-header a.ilc-login-button {
    float: right;
    position: static;
    margin: 0;
  }
}

.ilc-is-mobile .ilc-resource-view-toggles .ilc-icon {
  display: inline-block;
  width: 25px;
  padding: 5px;
  margin-left: -5px;
}

.ilc-is-mobile .ilc-resource-view-toggles a p {
  line-height: 13px;
  font-size: 9px;
}

.ilc-is-mobile .ilc-news-and-events .ilc-feed ul li {
  padding: 5px;
}

.ilc-is-mobile .ilc-continuing-education-banner {
  display: none;
}

.ilc-is-mobile .ilc-header.ilc-main-nav {
  padding: 0;
  margin: 0;
}

.ilc-is-mobile.ilc-layout input,
.ilc-is-mobile select {
  font-size: 16px;
}

.ilc-is-mobile .ilc-quick-search-products {
  .ilc-product-tile {
    min-width: 144px;
  }
  .ilc-manufacturer-profile-highlights.ilc-no-category {
    flex-wrap: wrap;
    padding: 12px;
    .ilc-banner-container {
      @media screen and (min-width: 700px) {
        width: 48%;
      }
      width: 100%;
    }
    .ilc-product-tile {
      min-width: 144px;
    }
  }
}

.ilc-is-mobile .ilc-resource-view-toggles {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 20px;
}

.ilc-is-mobile .ilc-resource-view-toggles a {
  text-align: center;
  width: 13%;
  margin-right: 3%;
  flex: 0 0 auto;
  padding: 10px;
  min-width: 75px;
}

.ilc-is-mobile .ilc-header {
  margin: auto;
}

.ilc-is-mobile .ilc-continuing-education-courses .ilc-course-tile {
  margin-bottom: 50px;
  border-bottom: solid 1px #ededed;
}

.ilc-is-mobile .ilc-continuing-education-courses .ilc-courses-remaining-link a {
  margin: unset;
}

@media screen and (min-width: 801px) {
  .ilc-is-mobile .ng-modal-dialog {
    left: 50%;
  }
}

/* Lineman 4 Mobile Styles */

.ilc-is-mobile .ilc-cart {
  order: -1;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  span {
    width: 5rem;
  }
}

.ilc-is-mobile .ilc-manufacturer-filter-bar {
  flex-direction: column;
  padding: 1rem;
  @media screen and (min-width: 700px) {
    margin-top: 2rem;
  }
  .ilc-header-text {
    width: 100%;
    padding-bottom: 0;
    padding-top: 1rem;
  }
  .ilc-filter-modal-overlay
    .ilc-filter-modal
    .ilc-filter-modal-content
    .ilc-dropdown-bins
    .ilc-mobile-filter-accordion-container {
    margin-bottom: 2rem;
    width: 100%;
    .ilc-mobile-accordion-toggle {
      @media screen and (min-width: 700px) {
        display: none;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #777777;
      color: white;
      padding: 0.7rem 0.8rem;
      .ilc-mobile-accordion-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.9rem;
        font-weight: 600;
      }
      .ilc-mobile-accordion-toggle-icon.active-accordion-mobile-toggle {
        margin-left: auto;
        font-size: 2rem;
        font-weight: 200;
        position: relative;
        right: 0;
        svg {
          transform: rotate(360deg);
        }
      }
      .ilc-mobile-accordion-toggle-icon {
        margin-left: auto;
        font-size: 2rem;
        font-weight: 200;
        position: relative;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        background-size: contain;
        bottom: 6px;
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .ilc-mobile-filter-accordion {
    @media screen and (min-width: 700px) {
      display: block;
    }
    display: none;
    .ilc-dropdown-ilc-header__bin-header {
      @media screen and (min-width: 700px) {
        display: block;
      }
      display: none;
    }
  }
  .ilc-mobile-filter-accordion.active-outer-accordion {
    display: block;
    background: black;
    padding-top: 1rem;
  }

  .ilc-quick-search-filters {
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 700px) {
      flex-direction: row;
      justify-content: left;
      margin-left: 0;
    }
    .ilc-input-container {
      width: 100%;
      margin-left: 0;
      justify-content: center;
      margin-bottom: 1rem;
      @media screen and (min-width: 700px) {
        margin-bottom: 0;
        width: 45%;
        margin-right: 1rem;
      }
      .ilc-searchbar-v2 {
        width: 100%;
      }
    }
    .ilc-filter-toggle-container {
      @media screen and (min-width: 700px) {
        width: 17rem;
        margin: 0rem 1rem 0rem 0rem;
      }
      margin-bottom: 1.5rem;
      width: 100%;
    }
  }
}

.ilc-is-mobile .ilc-manufacturer-profile-body {
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  .ilc-manufacturer-profile-highlights.ilc-no-category {
    flex-wrap: wrap;
    padding: 12px;
    .ilc-banner-container {
      @media screen and (min-width: 700px) {
        width: 48%;
        margin-top: 2rem;
      }
      width: 100%;
    }
    .ilc-product-tile {
      min-width: 144px;
    }
  }
  .ilc-manufacturer-info {
    @media screen and (min-width: 700px) {
      flex-direction: row;
    }
    width: 100%;
    justify-content: center;
    margin-right: 0;
    .ilc-manufacturer-info-actions {
      @media screen and (min-width: 700px) {
        width: 50%;
      }
      .ilc-action-link {
        @media screen and (min-width: 700px) {
          width: 75%;
        }
        width: 95%;
        margin-bottom: 1.5rem;
      }
    }
    .ilc-manufacturer-logo-link {
      @media screen and (min-width: 700px) {
        width: 50%;
      }
    }
  }
  .ilc-manufacturer-profile-product-area {
    .ilc-left-justified-banner {
      margin-top: 2.2rem;
      @media screen and (max-width: 700px) {
        margin-top: 0rem;
        margin-bottom: 2rem;
        .ilc-banner-container {
          flex-direction: column;
          img {
            width: 100%;
          }
          .ilc-banner-text-area {
            width: 100%;
            margin-left: 0;
            margin-top: 1rem;
          }
        }
      }
    }
    @media screen and (min-width: 700px) {
      margin-top: 2rem;
    }
    margin: 5rem 0rem 0rem 0rem;
    width: 100%;
    .ilc-quick-search-filters {
      width: 100%;
      @media screen and (min-width: 700px) {
        flex-direction: row;
        margin-bottom: 0;
      }
      flex-direction: column;
      .ilc-input-container {
        @media screen and (min-width: 700px) {
          width: 44%;
          margin-bottom: 0rem;
          margin-right: 1rem;
        }
        margin-bottom: 1rem;
        margin-left: 0;
        width: 100%;
        .ilc-searchbar-v2 {
          width: 100%;
        }
      }
      .ilc-select-container {
        @media screen and (min-width: 700px) {
          width: 20%;
          margin-bottom: 0;
          margin-right: 1rem;
        }
        width: 100%;
        margin-bottom: 1.5rem;
        select {
          width: 100%;
        }
      }
      .ilc-clear-products {
        @media screen and (min-width: 700px) {
          width: 16%;
        }
        width: auto;
      }
    }
    .ilc-product-actions {
      .ilc-cart-button {
        @media screen and (min-width: 700px) {
          width: 50%;
        }
        font-size: 0.7rem;
        width: 100%;
      }
      .ilc-compare-button {
        @media screen and (min-width: 700px) {
          display: flex;
        }
        font-size: 0.7rem;
        display: none;
      }
    }
    .ilc-quick-search-products .ilc-product-list .ilc-product-tile {
      @media screen and (min-width: 700px) {
        flex-basis: 22%;
      }
      flex-basis: 44%;
    }
  }
}

.ilc-is-mobile .ilc-breadcrumbs {
  @media screen and (min-width: 700px) {
    display: flex;
  }
  display: none;
}

.ilc-is-mobile .ilc-quick-search-display-v2 {
  .ilc-mobile-filter-accordion-container {
    margin-bottom: 2rem;
    width: 100%;
    .ilc-mobile-accordion-toggle {
      @media screen and (min-width: 700px) {
        display: none;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #777777;
      color: white;
      padding: 0.7rem 0.8rem;
      .ilc-mobile-accordion-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.9rem;
        font-weight: 600;
      }
      .ilc-mobile-accordion-toggle-icon.active-accordion-mobile-toggle {
        margin-left: auto;
        font-size: 2rem;
        font-weight: 200;
        position: relative;
        right: 0;
        svg {
          transform: rotate(360deg);
        }
      }
      .ilc-mobile-accordion-toggle-icon {
        margin-left: auto;
        font-size: 2rem;
        font-weight: 200;
        position: relative;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        background-size: contain;
        bottom: 6px;
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .ilc-mobile-filter-accordion {
      @media screen and (min-width: 700px) {
        display: block;
      }
      display: none;
      .ilc-dropdown-ilc-header__bin-header {
        @media screen and (min-width: 700px) {
          display: block;
        }
        display: none;
      }
    }
    .ilc-mobile-filter-accordion.active-outer-accordion {
      display: block;
      background: black;
      padding-top: 1rem;
    }
    .ilc-mobile-filter-value-container.grouped.active-accordion-container {
      display: block;
    }

    .ilc-mobile-filter-value-container {
      display: none;
      &.active-accordion-container {
        display: block;
      }

      .ilc-product-search-filter-form {
        display: flex;
        flex-direction: column;
        padding: 0.8rem 0.8rem;
        background: #f8f8f8;
        .ilc-product-search-filter-labels {
          margin-bottom: 0.3rem;

          .ilc-product-search-filter-count {
            color: gray;
          }

          .ilc-product-search-filter-values {
            float: left;
            width: 1rem;
            margin-right: 3px;
          }
        }
      }
    }
  }
  .ilc-search-results-v2 {
    .ilc-quick-search-products {
      @media screen and (min-width: 700px) {
        flex-direction: row;
      }
      flex-direction: column;
      .ilc-product-actions {
        .ilc-cart-button {
          @media screen and (min-width: 700px) {
            width: 50%;
          }
          font-size: 0.7rem;
          width: 100%;
        }
        .ilc-compare-button {
          @media screen and (min-width: 700px) {
            display: flex;
          }
          font-size: 0.7rem;
          display: none;
        }
      }
      .ilc-mobile-filter-accordion-container {
        @media screen and (min-width: 700px) {
          flex-basis: 33%;
        }
        margin-bottom: 2rem;
        width: 100%;
        .ilc-mobile-accordion-toggle {
          @media screen and (min-width: 700px) {
            display: none;
          }
          cursor: pointer;
          display: flex;
          align-items: center;
          background: #767676;
          color: white;
          padding: 0.7rem 0.8rem;
          border: 1px solid #c0c0c0;
          .ilc-mobile-accordion-title {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 0.9rem;
            font-weight: 600;
          }
          .ilc-mobile-accordion-toggle-icon.active-accordion-mobile-toggle {
            margin-left: auto;
            font-size: 2rem;
            font-weight: 200;
            position: relative;
            right: 0;
            svg {
              transform: rotate(360deg);
            }
          }
          .ilc-mobile-accordion-toggle-icon {
            margin-left: auto;
            font-size: 2rem;
            font-weight: 200;
            position: relative;
            right: 0;
            width: 1.5rem;
            height: 1.5rem;
            display: block;
            background-size: contain;
            bottom: 6px;
            svg {
              transform: rotate(180deg);
            }
          }
        }
        .ilc-mobile-filter-accordion {
          @media screen and (min-width: 700px) {
            display: block;
          }
          display: none;
        }
        .ilc-mobile-filter-accordion.active-outer-accordion {
          display: block;
          background: #f8f8f8;
          padding-top: 1rem;
          .ilc-mobile-filter-value-container {
            display: block;
            .ilc-product-search-filter-form {
              display: flex;
              flex-direction: column;
              padding: 0.8rem 0.8rem;
              background: #f8f8f8;
              .ilc-product-search-filter-labels {
                margin-bottom: 0.3rem;

                .ilc-checkboxes {
                  float: left;
                  width: 1rem;
                  margin-right: 3px;
                }
              }
              .ilc-product-search-filter-count {
                color: gray;
              }
            }
          }
          .ilc-mobile-filter-value-container.grouped.active-accordion-container {
            display: block;
          }

          .ilc-mobile-filter-value-container {
            display: none;
            &.active-accordion-container {
              display: block;
            }

            .ilc-product-search-filter-form {
              display: flex;
              flex-direction: column;
              padding: 0.8rem 0.8rem;
              background: #f8f8f8;
              .ilc-product-search-filter-labels {
                margin-bottom: 0.3rem;

                .ilc-checkboxes {
                  float: left;
                  width: 1rem;
                  margin-right: 3px;
                }
              }
              .ilc-product-search-filter-count {
                color: gray;
              }
            }
          }
        }
      }
    }

    .ilc-results-top {
      flex-direction: column;
      margin-top: 3rem;
      .ilc-result-counts {
        margin-bottom: 1rem;
      }
      .ilc-quick-search-filters {
        align-self: auto;
        flex-direction: column;
        @media screen and (min-width: 700px) {
          flex-direction: row;
        }
        select {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .ilc-quick-search-v2-header.layout-v2 {
    margin-top: 2rem;
    .ilc-filter-toggle-container {
      @media screen and (min-width: 700px) {
        margin-right: 1rem;
        width: 17rem;
      }
      width: 100%;
    }
    .ilc-input-container.simplified {
      @media screen and (min-width: 700px) {
        width: 40%;
        margin-bottom: 0rem;
        margin-right: 1rem;
      }
      margin-bottom: 1rem;
      margin-left: 0;
      width: 100%;
      .ilc-searchbar-v2 {
        width: 100%;
      }
    }
  }
}

.ilc-is-mobile .ilc-quick-search-display-v2 {
  .ilc-quick-search-v2-header.layout-v2 {
    flex-direction: column;
    padding: 1rem;
    .ilc-header-text {
      width: 97%;
      padding-bottom: 1rem;
      padding-top: 0.5rem;
    }
    .ilc-search-actions {
      margin-bottom: 1rem;
    }
    .ilc-input-container {
      margin-left: 0;
      @media screen and (max-width: 500px) {
        width: 100%;
      }
      .ilc-searchbar-v2 {
        width: 100%;
      }
    }
  }
}

.ilc-is-mobile .ilc-product-search-page-heading {
  flex-direction: column;
  .ilc-quick-search-filters {
    margin-left: 0;
    align-self: normal;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      margin-bottom: 0;
      width: 100%;
    }
    select {
      @media screen and (max-width: 700px) {
        margin-bottom: 1rem;
        min-width: 0;
      }
    }
  }
}

.ilc-is-mobile .ilc-compare-bar-container {
  display: none;
  @media screen and (min-width: 700px) {
    display: block;
    width: 94%;
  }
  @media screen and (min-width: 700px) {
    .ilc-compare-bar {
      width: 93%;
      margin-left: 1rem;
    }
  }
}

.ilc-is-mobile .ilc-product-page-body {
  grid-template:
    "main"
    "sidebar"
    "similar";
  .ilc-similar-products-container {
    margin-top: 1rem;
    margin-bottom: 3rem;
    margin-left: 0;
    width: 100%;
    .ilc-quick-search-products .ilc-product-list {
      @media screen and (min-width: 700px) {
        flex-wrap: wrap;
        overflow-x: visible;
      }
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-bottom: 1.5rem;
      margin-left: 0;
      .ilc-product-tile {
        @media screen and (min-width: 700px) {
          flex-basis: 17%;
          width: auto;
        }
        flex-basis: 44%;
        margin-right: 0.7rem;
        margin-bottom: 1rem;
        flex-shrink: 0;
      }
    }
  }

  .ilc-product-sidebar-container {
    width: 100%;
    h4 {
      margin-top: 2rem;
    }
    .ilc-action-link {
      @media screen and (min-width: 700px) {
        width: 28%;
      }
      width: 60%;
    }
  }

  .ilc-main-product-info-container {
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
    margin-right: 0;
    .ilc-product-image-gallery-container {
      @media screen and (min-width: 700px) {
        order: 1;
      }
      width: 100%;
      order: 2;
      .image-gallery-thumbnails-wrapper.left {
        margin: 0;
      }
    }
    .ilc-product-details-container {
      @media screen and (min-width: 700px) {
        margin-bottom: 0;
        order: 2;
      }
      width: 83%;
      margin-bottom: 2rem;
      order: 1;
    }
  }
}

.ilc-is-mobile .ilc-product-comparison-page-container {
  .ilc-product-comparison-page-header-container {
    flex-direction: column;
    .ilc-page-action-buttons-container {
      margin-left: 0;
    }
  }
  .ilc-product-comparison-main-body {
    @media screen and (min-width: 700px) {
      overflow-x: visible;
    }
    overflow-x: auto;
    .ilc-compared-products-table {
      @media screen and (min-width: 700px) {
        width: 90%;
      }
      width: 870px;
    }
    .ilc-quick-search-products {
      @media screen and (min-width: 700px) {
        width: 90%;
      }
      width: 800px;
      .ilc-product-list {
        @media screen and (min-width: 700px) {
          gap: 3%;
          margin-left: 6rem;
        }
        gap: 5%;
        margin-left: 8rem;
        .ilc-product-compare-item {
          @media screen and (min-width: 700px) {
            width: 23%;
          }
          width: 30%;
          .ilc-product-name {
            @media screen and (min-width: 700px) {
              height: 80px;
            }
          }
        }
        .ilc-action-link {
          @media screen and (min-width: 700px) {
            font-size: 0.7rem;
          }
          .ilc-action-icon {
            @media screen and (min-width: 700px) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.ilc-is-mobile .ilc-saved-items-body {
  .ilc-saved-items-page-header-container {
    @media screen and (min-width: 700px) {
      flex-direction: row;
      align-items: center;
    }
    flex-direction: column;
    align-items: normal;
    .ilc-saved-items-page-heading {
      @media screen and (min-width: 700px) {
        margin-bottom: 0;
      }
      margin-bottom: 1rem;
    }
    .ilc-page-action-buttons-container {
      @media screen and (min-width: 700px) {
        margin-left: auto;
      }
      margin-left: 0;
    }
  }
  .ilc-saved-items-table {
    .ilc-saved-items-table-header-container {
      @media screen and (min-width: 700px) {
        display: block;
      }
      display: none;
    }
    .ilc-saved-items-table-row {
      @media screen and (min-width: 700px) {
        border-bottom: 1px solid #d6d6d6;
        border-left: 1px solid #d6d6d6;
      }
      border-top: 1px solid #d6d6d6;
      border-right: 1px solid #d6d6d6;
      .ilc-saved-items-table-value-box:nth-child(n + 2) {
        @media screen and (min-width: 700px) {
          display: flex;
        }
        display: none;
      }
      .ilc-saved-items-table-value-box:nth-child(1) {
        @media screen and (min-width: 700px) {
          width: 34%;
        }
        width: 100%;
      }
    }
  }
}

.ilc-is-mobile .ilc-filter-modal {
  top: 8%;
}

.ilc-is-mobile
  .ilc-search-results-v2
  .ilc-quick-search-products
  .ilc-product-list.sidebar-open {
  justify-content: center;
  @media screen and (min-width: 700px) {
    justify-content: left;
  }
}

@media screen and (max-width: 480px) {
  .ilc-is-mobile .ilc-clear-products {
    width: auto;
    margin-left: 0;
  }
  .ilc-is-mobile
    .ilc-quick-search-display-v2
    .ilc-quick-search-v2-header.layout-v2
    .ilc-search-actions,
  .ilc-is-mobile .ilc-manufacturer-filter-bar .ilc-quick-search-filters {
    margin-left: 0;
  }
}
