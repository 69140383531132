.ilc-quick-search-products
  .ilc-manufacturer-profile-highlights.ilc-no-category {
  margin-left: 0;
  max-width: 96.5%;
  &.ilc-flex-wrap {
    flex-wrap: wrap;
  }
}
.ilc-manufacturer-profile-body,
.ilc-quick-search-products {
  .ilc-manufacturer-profile-highlights.ilc-no-category:empty {
    display: none;
  }
  .ilc-manufacturer-profile-highlights.ilc-no-category {
    display: flex;
    flex-flow: nowrap;
    gap: 50px 25px;
    padding: 20px;
    width: 95%;
    margin-bottom: 2rem;
    .ilc-banner-container {
      @media screen and (max-width: 1200px) {
        width: 45%;
      }
      width: 31%;
      height: fit-content;
      display: flex;
      flex-flow: column;
      img {
        width: 100%;
      }
      .ilc-banner-title {
        margin-top: 1.33rem;
        margin-bottom: 0.5rem;
      }
      .ilc-banner-manufacturer {
        font-style: italic;
      }
      .ilc-banner-description {
        margin-top: 0.5rem;
        overflow: hidden;
        margin-bottom: 1.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .ilc-banner-container {
    a {
      width: fit-content;
      padding: 7px 15px;
      min-width: 100px;
      text-align: center;
      font-size: 13px;
      background: #000;
      color: #fff;
    }
  }
}

.ilc-manufacturer-dialiog-share-sheet {
  .ilc-manufacturer-dialog-share-sheet-body {
    .share-heading {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    .share-link-textbox {
      width: 75%;
      padding: 0.3rem;
      margin-bottom: 1.5rem;
    }
    .share-button {
      margin-bottom: 1rem;
      padding: 0.7rem 0.7rem;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      line-height: 2rem;
      background: #000;
      color: white;
    }
    .share-general-text {
      font-size: 0.8rem;
      margin-top: 1rem;
      padding-bottom: 1.5rem;
      line-height: normal;
    }
  }
}

.ilc-manufacturer-profile-body {
  display: flex;
  .ilc-manufacturer-info {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    width: 25%;
    .ilc-manufacturer-logo {
      width: 100%;
      margin-bottom: 1.7rem;
    }
    .ilc-action-link {
      display: flex;
      border: 1px solid #dedede;
      border-radius: 5px;
      padding: 0.5rem;
      align-items: center;
      width: 90%;
      color: #808080;
      margin-bottom: 1rem;
      font-size: 0.9rem;
      cursor: pointer;
      .ilc-action-icon {
        width: 15px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
    .ilc-manufacturer-description {
      margin-top: 2rem;
      font-size: 1rem;
      line-height: 1.3;
      word-break: break-word;
    }
    .ilc-manufacturer-social-icons {
      display: flex;
      margin-top: 1rem;
      .ilc-manufacturer-social-link {
        width: 30px;
        height: 30px;
        display: block;
      }
      .ilc-manufacturer-social-twitter {
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath d='M206.25,103.51c-1.52,5.49-4.74,8.92-7.87,12.88l9.88-2.48a5.82,5.82,0,0,1-.68,2,105.89,105.89,0,0,1-8,8.18,4.87,4.87,0,0,0-2.09,4.2c.25,15.47-3.69,29.68-13.12,42.13-11.21,14.79-26.15,23.49-44.36,26.56-16.21,2.73-31.63,0-46.23-7.45-.55-.29-1.09-.59-1.62-.93-.13-.08-.17-.3-.47-.89a29.62,29.62,0,0,1,3-.41c7.91-.25,15.69-1.2,22.89-4.77a37.64,37.64,0,0,0,6.67-4.51c-10.46-1.77-17.11-7.71-21.22-17.64h9l.42-.68c-10.89-4.11-16.27-12-17.2-23.36l9.66,2.69.53-.75c-10.11-9-13.35-19.28-7-31.45l1.42-1a10,10,0,0,0,1.31,2.64c10.71,11.24,23,19.73,38.74,22.26,1.75.28,3.57.24,5.32.54,2.35.4,3.23-.31,3-2.81a23.2,23.2,0,0,1,5.16-17A25.05,25.05,0,0,1,188.05,107c2.93,2.25,5.29,2.61,8.4,1S202.53,105.21,206.25,103.51Z'/%3E%3C/svg%3E");
        background-size: 45px 45px;
        background-position: center;
      }
      .ilc-manufacturer-social-facebook {
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath d='M137.81,181.57c0-7.58-.06-15.17,0-22.75,0-2-.62-2.67-2.61-2.61-4.4.11-8.8,0-13.2.06-1.91,0-2.73-.52-2.69-2.57.11-5.39.09-10.77,0-16.16,0-1.83.71-2.38,2.42-2.36,4.47.06,8.95-.06,13.42,0,2,0,2.65-.63,2.65-2.63,0-5.6-.06-11.23.33-16.82.75-10.69,7.39-19.15,17-21.88a23.54,23.54,0,0,1,6.73-.9c5.45.08,10.91.49,16.36.67,1.75.06,2.41.79,2.38,2.51q-.1,6.48,0,13c0,1.68-.7,2.31-2.32,2.29-2.65,0-5.31-.09-8,0-6.6.33-9.9,3.84-9.92,10.43,0,3.64.06,7.29,0,10.92,0,1.8.69,2.4,2.43,2.38,4.78-.07,9.56,0,14.33,0,2,0,2.73.53,2.34,2.68-.93,5.07-1.61,10.19-2.42,15.28-.49,3.1-.51,3.1-3.75,3.1h-9.78c-3.05,0-3.12.06-3.12,3q0,22.06,0,44.14c0,3.59,0,3.6-3.46,3.59-5.54,0-11.08-.06-16.61,0-2,0-2.64-.72-2.62-2.67C137.85,196.74,137.8,189.16,137.81,181.57Z'/%3E%3C/svg%3E");
        background-size: 45px 45px;
        background-position: center;
      }
      .ilc-manufacturer-social-linkedin {
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath d='M153.47,140.69c7.42-8.11,16.7-9.18,26.48-8.15,11.7,1.23,20.24,10.64,20.44,22.73.24,14.23.05,28.48.1,42.72,0,1.9-.63,2.68-2.61,2.65-4.91-.1-9.83-.14-14.73,0-2.4.07-3.19-.74-3.17-3.14.1-10.75,0-21.49.05-32.23,0-5.05-1.5-9.41-5.88-12.27-4.21-2.74-8.71-2.71-13.07-.43-4.7,2.45-7.5,6.39-7.56,11.79-.12,10.9,0,21.81,0,32.72,0,3.5,0,3.51-3.41,3.52H136.58c-3.45,0-3.51-.06-3.51-3.48q0-29.73,0-59.46c0-.75.1-1.5.06-2.25-.09-1.66.55-2.4,2.31-2.37q7.76.12,15.49,0c2,0,2.69.79,2.56,2.69C153.39,137.39,153.47,139,153.47,140.69Z'/%3E%3Cpath d='M101.48,166.75q0-15.24,0-30.46c0-3.12.08-3.2,3.26-3.21,4.82,0,9.65,0,14.48,0,1.94,0,2.83.54,2.82,2.69q-.09,31.08,0,62.17c0,2.08-.74,2.76-2.77,2.72-5-.1-10-.12-15,0-2.23.06-2.86-.83-2.85-2.94C101.51,187.39,101.47,177.07,101.48,166.75Z'/%3E%3Cpath d='M111.64,99.35A12.13,12.13,0,0,1,124,111.65,12.44,12.44,0,0,1,111.79,124a12.25,12.25,0,0,1-12.31-12.46A11.9,11.9,0,0,1,111.64,99.35Z'/%3E%3C/svg%3E");
        background-size: 45px 45px;
        background-position: center;
      }
      .ilc-manufacturer-social-youtube {
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath d='M112.05,186.6c-3.81-.29-7.57-1.29-10.61-3.93-3.51-3.06-4.69-7.25-5.16-11.6a198.6,198.6,0,0,1,0-43.12c1.08-9.71,6.06-14.55,16-15.34,10.78-.85,21.61-1.65,32.41-1.6,14.14,0,28.28.83,42.4,1.57,5,.26,9.93,1.55,12.88,6a20.72,20.72,0,0,1,3.08,8,147.91,147.91,0,0,1-.17,47c-1.28,7.53-5,11.36-12.63,12.49-7.07,1-14.23,1.56-21.37,1.91-6.31.31-14.23.17-19,.07C140.46,187.92,124.66,187.53,112.05,186.6Zm27.39-52.72v31.69l27.36-16Z'/%3E%3C/svg%3E");
        background-size: 45px 45px;
        background-position: center;
      }
      .ilc-manufacturer-social-instagram {
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath d='M93.18,149.81q0-13,0-26a30.55,30.55,0,0,1,30.36-30.44q26.12-.1,52.23,0a30.59,30.59,0,0,1,30.91,30.45q.26,26,0,52a30.66,30.66,0,0,1-30.54,30.68q-26.37.36-52.73,0a30.66,30.66,0,0,1-30.22-30.93Q93.16,162.68,93.18,149.81Zm56.48,46.26c8.66,0,17.33.09,26,0a20.51,20.51,0,0,0,20.56-20.75q0-25.25,0-50.5a21.27,21.27,0,0,0-.33-4.22c-2.11-10.24-10.27-16.94-20.85-17-16.42-.07-32.83,0-49.25,0-13.3,0-22.2,8.72-22.27,22q-.14,24.51,0,49c.07,12.7,9,21.47,21.66,21.51C133.32,196.1,141.49,196.07,149.66,196.07Z'/%3E%3Cpath d='M119.11,149.75a30.77,30.77,0,0,1,30.79-30.88c17,0,31.27,14.12,31.3,30.93,0,17-14.18,31.11-31.35,31.15A30.82,30.82,0,0,1,119.11,149.75Zm50.71,0a19.93,19.93,0,0,0-20-19.71c-10.77,0-19.88,9-19.93,19.63a20,20,0,1,0,39.94.08Z'/%3E%3Cpath d='M186.94,119.69a6.83,6.83,0,0,1-6.82,6.82,7,7,0,0,1-6.77-6.91,6.8,6.8,0,0,1,13.59.09Z'/%3E%3C/svg%3E");
        background-size: 45px 45px;
        background-position: center;
      }
      .ilc-manufacturer-social-pinterest {
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cg id='Ky9u74'%3E%3Cpath d='M133.67,202.62c.65-1.13,1.29-2.21,1.92-3.3A52,52,0,0,0,141.35,185c.72-3.14,1.62-6.24,2.44-9.36,0-.14.07-.27.11-.4,0,0,.09,0,.1,0a12.71,12.71,0,0,0,7.1,5.29,18.54,18.54,0,0,0,9,.65,26.22,26.22,0,0,0,17.51-10.57,36.47,36.47,0,0,0,7.07-18,35.32,35.32,0,0,0-.27-11.65,30.33,30.33,0,0,0-13-19.45,34.93,34.93,0,0,0-19.28-6,42.42,42.42,0,0,0-16.4,2.61,31.42,31.42,0,0,0-17.3,15.15,32.51,32.51,0,0,0-3.68,14.72,28.13,28.13,0,0,0,4.51,15.92,16.41,16.41,0,0,0,6.23,6,5,5,0,0,0,1,.43.82.82,0,0,0,1.06-.52,6.22,6.22,0,0,0,.25-.84c.48-1.94.95-3.87,1.43-5.81a1.65,1.65,0,0,0-.29-1.5,18.44,18.44,0,0,1-3.67-8.41,23.08,23.08,0,0,1,6.9-21.5,22.76,22.76,0,0,1,12.2-6.11,27,27,0,0,1,13.27.7,20.56,20.56,0,0,1,13.72,13.11,24.48,24.48,0,0,1,1.31,9.15,38.24,38.24,0,0,1-1,8,26.52,26.52,0,0,1-3.87,9,16,16,0,0,1-7.82,6.32,11.27,11.27,0,0,1-7.47.34,7.73,7.73,0,0,1-5.27-9.38c.71-2.91,1.63-5.77,2.48-8.65a53.76,53.76,0,0,0,1.94-7.79,10.54,10.54,0,0,0-.55-5.75,6.51,6.51,0,0,0-7.22-3.94,8.87,8.87,0,0,0-6.16,3.87,15.34,15.34,0,0,0-2.81,8.93,20.47,20.47,0,0,0,1.52,8.43,1.06,1.06,0,0,1,0,.56c-1.29,5.5-2.6,11-3.89,16.51-.88,3.74-1.78,7.47-2.59,11.23a32.89,32.89,0,0,0-.52,8.17c.08,2.27.25,4.53.39,6.87a55.1,55.1,0,0,1-33.59-39.34,54.93,54.93,0,1,1,37.35,40.75Z'/%3E%3C/g%3E%3C/svg%3E");
        background-size: 45px 45px;
        background-position: center;
      }
      .ilc-manufacturer-social-bimfiles {
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath d='M100,100V200H200V100Zm35.21,65.44c-3.18,2.73-5.54,3-11,3H113.54a.44.44,0,0,1-.44-.43V134.37a.42.42,0,0,1,.43-.42h12c3.61,0,6.73.85,8.58,2.33h0a8.09,8.09,0,0,1,3,6.76,8.2,8.2,0,0,1-3.52,6.44.42.42,0,0,0,.07.71,7.85,7.85,0,0,1,4.66,7.69A10,10,0,0,1,135.21,165.44Zm13.46,2.48a.44.44,0,0,1-.44.43h-7.91a.43.43,0,0,1-.43-.43V134.3a.43.43,0,0,1,.43-.43h7.91a.44.44,0,0,1,.44.43Zm40.08.41H181.1a.41.41,0,0,1-.41-.38l-1.62-17.47a.42.42,0,0,0-.81-.09L172.61,168a.43.43,0,0,1-.4.29H167a.41.41,0,0,1-.39-.29l-5.66-17.65a.42.42,0,0,0-.81.09L158.51,168a.41.41,0,0,1-.41.38h-7.65a.42.42,0,0,1-.42-.47l4.32-33.69a.42.42,0,0,1,.42-.36h8.84a.42.42,0,0,1,.4.29l5.25,17.08a.42.42,0,0,0,.8,0l5.08-17.08a.41.41,0,0,1,.4-.29h8.89a.4.4,0,0,1,.41.36l4.32,33.69A.41.41,0,0,1,188.75,168.33Z'/%3E%3Cpath d='M128.27,154.8a7.54,7.54,0,0,0-2.69-.36h-3.8a.42.42,0,0,0-.42.42v5.31a.43.43,0,0,0,.43.43h3.79a6.77,6.77,0,0,0,2.59-.4,2.84,2.84,0,0,0,1.85-2.83A2.52,2.52,0,0,0,128.27,154.8Z'/%3E%3Cpath d='M121.81,147.74h2.72a7.1,7.1,0,0,0,2.59-.38,2.74,2.74,0,0,0,1.86-2.73,2.44,2.44,0,0,0-1.75-2.48,8,8,0,0,0-2.7-.34h-2.71a.43.43,0,0,0-.43.43v5.07A.42.42,0,0,0,121.81,147.74Z'/%3E%3C/svg%3E");
        background-size: 45px 45px;
        background-position: center;
      }
    }
  }
  .ilc-manufacturer-profile-product-area {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    width: 75%;
    .ilc-left-justified-banner {
      margin-bottom: 2rem;
      .ilc-banner-container {
        display: flex;
        img {
          width: 50%;
        }
        .ilc-banner-text-area {
          display: flex;
          flex-direction: column;
          margin-left: 1rem;
          width: 50%;
          .ilc-banner-title {
            margin-top: 0;
            margin-bottom: 0;
          }
          .ilc-banner-manufacturer {
            font-style: italic;
          }
          .ilc-banner-description {
            margin-top: 0;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.2;
          }
          .ilc-banner-link {
            margin-top: 0.5rem;
            text-transform: capitalize;
          }
        }
      }
    }
    .ilc-quick-search-filters {
      align-self: flex-start;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
      .ilc-select-container {
        @media screen and (max-width: 1200px) {
          background-position: right 15px top 15px;
        }
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 -300 1000 1100' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' %3E%3Cpath fill='%23808080' d='m0 150l230 400l230-400l-460 0z' horiz-adv-x='460' /%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 15px center;
        background-size: 15px;
        select {
          @media screen and (max-width: 1200px) {
            margin-bottom: 1rem;
          }
          font-size: 0.7rem;
          background: transparent;
          border: none;
          margin-bottom: 0;
          cursor: pointer;
          margin-right: 0.7rem;
          border: 1px solid #dedede;
          width: 10rem;
          border-radius: 5px;
          appearance: none;
          min-width: 0;
        }
      }
      .ilc-input-container {
        @media screen and (max-width: 1200px) {
          width: 70%;
          margin-bottom: 1rem;
        }
        width: 35rem;
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 1rem;
        .ilc-searchbar-v2 {
          border-radius: 50px;
          border: 1px solid #dedede;
          height: 32px;
          padding: 1rem 1rem 1rem 2rem;
          text-align: left;
          width: 100%;
          line-height: normal;
          font-size: 0.8rem;
          margin-bottom: 0px;
        }
        .ilc-searchbar-icon {
          position: absolute;
          top: 10px;
          left: 10px;
          svg {
            width: 15px;
            transform: rotate(100deg);
            font-weight: 700;
          }
          svg path {
            fill: #707070;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        .ilc-filter-toggle-container {
          width: 30%;
        }
      }
      .ilc-clear-products {
        @media screen and (max-width: 1200px) {
          width: auto;
          margin-left: auto;
          margin-right: auto;
        }
        color: #333;
      }
    }
    .ilc-quick-search-products {
      min-height: unset;
      .ilc-results-count {
        color: #808080;
        font-size: 0.9rem;
      }
      .ilc-product-list {
        margin-left: 0;
        .ilc-product-tile {
          flex-basis: 17%;
          min-width: 0;
          width: auto;
          max-width: none;
          h4 {
            font-size: 1rem;
          }
          .ilc-product-description {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
