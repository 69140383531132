.ilc-product-tile {
  .ilc-product-description {
    line-height: 1.4em;
    height: 3.7em;
    overflow: hidden;
    padding: 0.7em;
    margin-bottom: 0.5em;
    font-size: 0.9em;
  }
}
